import React from 'react'
import { Link } from 'react-router-dom'
export default function SearchHead() {
  return (
    <div className="search-box">
      <input type="text" className="search-input" placeholder="Search" />
      <Link to="" className="search-btn " >
        <svg width="27" height="27" className='w-[20px] h-[20px] sm-w-[27px] sm:h-[27px]' viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26 26L20.0974 20.0868M23.3684 12.1842C23.3684 15.1504 22.1901 17.9952 20.0926 20.0926C17.9952 22.1901 15.1504 23.3684 12.1842 23.3684C9.21797 23.3684 6.37323 22.1901 4.27578 20.0926C2.17833 17.9952 1 15.1504 1 12.1842C1 9.21797 2.17833 6.37323 4.27578 4.27578C6.37323 2.17833 9.21797 1 12.1842 1C15.1504 1 17.9952 2.17833 20.0926 4.27578C22.1901 6.37323 23.3684 9.21797 23.3684 12.1842V12.1842Z" stroke="#27295B" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </Link>
    </div>
  )
}
