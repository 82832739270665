import React, { useState, useEffect, useRef } from "react";
import Footer from "../../component/Footer";
import Breadcrumb from "./Breadcrumb";
import NeedHelp from "./NeedHelp";
// import { TbPointFilled } from "react-icons/tb";
import { Link } from "react-router-dom";
import axios from "axios";
import { decodedStringValue } from "../../_helpers";
import { Helmet } from 'react-helmet';

export default function GreffesDeFrance() {
  /* =====================|Get Default Values|================== */
  const searchRef = useRef(null);
  const [search, setSearch] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [paginateData, setPaginateData] = useState({
    "current": 1,
    "total_pages": 0,
    "total_results": 0,
    "size": 100
  });
  const [breadcrumbsData, setBreadcrumbsData] = useState({
    "breadCrumbs":[
      {"pagename":"Annuaires","path":"/greffes-de-france"}
    ],
    "main":{"pagename":"Greffes de France","path":"/greffes-de-france"}
  });
  // Search Input data
  function getInputSearchData(input) {
    setSearchKey(input);
    if (input) {
     
      axios
        .post(
          process.env.REACT_APP_API_URL +
            `api/admin/search_directive_greffe`,
          { search: input ,size:10,currentPage:1 }
        )
        .then((response) => {
          // Handle a successful response
          if((response.data.data).length>0){
            let resultList  = response.data.data.filter((val,i)=>val._source.villefranche_cedex && val._source.code_postal)
            setSearch(resultList);
            setCheckList(true);
          }else{
            setSearch(response.data.data);
          }
          
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error(error);
          setCheckList(false);
        });

    } else {
      setCheckList(false);
    }
  }
  // ========================================
  const [checkList, setCheckList] = useState(false);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [search, checkList]);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setCheckList(false);
    }
  };
  
  return (
    <>
   <Helmet>
        <title>Annuaire des Greffes en France | BusyPlace - Simplifiez vos Démarches Légales </title>
        <meta name="description" content="Découvrez notre annuaire des greffes dans toute la France avec BusyPlace. Profitez d'un accès facilité pour toutes vos nécessités légales et administratives, et assurez la conformité de vos démarches en un clin d'œil" />
        {/* <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/greffes-de-france`} /> */}
      </Helmet>
      <div className='w-full h-full AnnuaireBg pb-6 py-2 md:py-0 md:min-h-[359px]' >
        <div className='md:max-w-[1477px] relative w-full h-full m-auto py-0 md:py-8 px-4 sm:px-14'>
          <div className='flex items-center justify-start sm:gap-4 w-full m-auto'>
            <Breadcrumb page="Greffes de France"   breadcrumbsData={breadcrumbsData} />
          </div>
          <div className='max-w-[1277px] m-auto my-8 md:mt-16 flex justify-center items-center flex-col gap-4'>
            <h1 className=' text-2xl text-center lg:text-3xl 3xl:text-[35px] font-black text-primary'>L’annuaire des Greffes de France </h1>            
            <div
              style={{
                position: "relative",
              }}
            >
              <form
                id="searchForm" 
                style={{ zIndex:"9" }}
                className="bg-white border-0 w-100 max-w-[727px] px-5 py-4  rounded flex justify-between w-full"
              >
                <input
                  onInput={(e) => getInputSearchData(e.target.value)}
                  type="text"
                  className=" w-full outline-none text-[#797979] text-base font-light"
                  placeholder="Nom de la ville ou du département"
                />
                <Link>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M33 33L25.6807 25.6677M29.7368 15.8684C29.7368 19.5466 28.2757 23.074 25.6749 25.6749C23.074 28.2757 19.5466 29.7368 15.8684 29.7368C12.1903 29.7368 8.6628 28.2757 6.06197 25.6749C3.46113 23.074 2 19.5466 2 15.8684C2 12.1903 3.46113 8.6628 6.06197 6.06197C8.6628 3.46113 12.1903 2 15.8684 2C19.5466 2 23.074 3.46113 25.6749 6.06197C28.2757 8.6628 29.7368 12.1903 29.7368 15.8684V15.8684Z"
                      stroke="#27295B"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                  </svg>
                </Link>
              </form>
              <div
                style={{ display: checkList ? "block" : "none",zIndex:"9" }}
                className="searchListBox max-w-[727px] sm:mx-auto"
                ref={searchRef}
              >
                <ul>
                  {search.length === 0 ? (
                    <div
                      className="containertype"
                      style={{ textAlign: "left", padding: "9px 19px" }}
                    >
                      <span>No search result found!</span>
                      {/* <div className="dottype"></div>
                      <div className="dottype"></div>
                      <div className="dottype"></div> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {search.map((listdata) => {
                    let list = listdata._source;
                    return (
                      <li>
                        <Link
                          onClick={(e)=>{
                            setCheckList(false)
                            setPaginateData({
                              "current": 1,
                              "total_pages": 0,
                              "total_results": 0,
                              "size": 100
                            })
                          }}
                          className="ctmref"
                          // to={`/annuaire-des-greffe-selection/${list.villefranche_cedex}/${list.code_postal}`}
                          to={`/directory_search_result/greffe/${searchKey}/${paginateData.size}/${paginateData.current}`}
                        >
                          {list.villefranche_cedex} ({list.code_postal})
                        </Link>
                      </li>
                    );
                  })}
                  <li className={search.length>0?"":"hidden"} style={{"fontWeight":"700"}}>
                    <Link
                      onClick={(e)=>{
                        setCheckList(false)
                        setPaginateData({
                          "current": 1,
                          "total_pages": 0,
                          "total_results": 0,
                          "size": 100
                        })
                      }}
                      className="ctmref"
                      to={`/directory_search_result/greffe/${searchKey}/${paginateData.size}/${paginateData.current}`}
                    >
                      {"Voir tous les résultats de la recherche"}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div >
      <div className='w-full h-full min-h-[359px]  px-4 sm:px-14' >
        <div className='max-w-[1057px] relative w-full h-full m-auto mt-8 sm:mt-20'>
          <h2 className='font-semibold text-xl md:text-3xl text-primary'>En France, les greffes des tribunaux de commerce sont chargés de deux types de missions.</h2>
          <p className='text-[17px] font-normal text-primary mt-3'>Ils sont d’abord chargés d’assurer la gestion administrative des audiences des juridictions auxquelles ils sont rattachés en vue de veiller au bon déroulement de ces audiences et de faire en sorte que les débats qui se déroulent soient consignés par écrit.</p>
          <p className='text-[17px] font-normal text-primary mt-3'>D'autre part, ils sont chargés de dactylographier les jugements rendus par les juges consulaires et de les transmettre aux parties concernées. Ils doivent aussi signer les jugements rendus pour les authentifier et assurer la tenue à jour des dossiers des entreprises immatriculées aux registres du commerce et des sociétés. En conséquence, ils reçoivent les dossiers transmis par les centres de formalités des entreprises lors des créations de société.</p>

          <h2 className='font-semibold text-xl md:text-3xl  text-primary mt-8 sm:mt-16'>Annonces légales et Greffe</h2>
          <p className='text-[17px] font-normal text-primary mt-3'>C'est au greffe rattaché à votre localité que revient l'attestation de dépôt d'annonce légale nécessaire à la constitution de votre dossier. En outre, les greffes des tribunaux de commerce sont informés de l'ouverture de toute procédure de redressement ou de liquidation judiciaire et ils se chargent de la publication des jugements rendus à l'occasion de ces procédures. Il en est de même en cas de procédure de sauvegarde. </p>

          <p className='text-[17px] font-normal text-primary mt-3'>Enfin, ils reçoivent les comptes annuels de toutes les sociétés tenues de publier leurs comptes et ils se chargent de mettre ces documents à la disposition du public pour que celui-ci puisse en prendre connaissance.</p>

        </div>
      </div>
      
        <NeedHelp /> 
      <Footer />
    </>
  )
}