import React, { useState, useEffect, useRef } from "react";
// import FridLogo from "../assets/FridLogo.png";
import no_image from "../assets/no_image.png";
import Compétences from "../assets/Compétences.svg";
import { FiMapPin } from "react-icons/fi";
import messages from "../assets/messages.svg";
import akarIconsCalendar from "../assets/akar-icons_calendar.svg";
import tel from "../assets/tel.svg";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import searchIcon from "../assets/search.svg";
import Footer from "../component/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import Mapbox from "../component/mapbox/Mapbox";
import {getLatLngFromAddress} from "../component/mapbox/Mapbox";

import MessageProFormComp from "../component/messageProForm";
import PageLoaderComp from "../component/pageLoaderComp";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { decodedStringValue } from "../_helpers";
import Breadcrumb from "./Directory/Breadcrumb";
import ToastMessageComp from "../component/ToastMessageComp";
import { useSelector } from "react-redux";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];
export default function DirectoryOfNotaires() {
  const alert = useSelector(state => state.alert);
  const { city, postalCode } = useParams();
  const [data, setData] = useState([]);
  const [successfull, setSuccessfull] = useState(false);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(100);
  const [loadingFullpage, setLoadingFullpage] = useState(false);
  const [selectedFilterData, setSelectedFilterData] = useState( { "domaines":[]  , "langues":[],"accessibilites":[] }
  );
  const [filterData, setFilterData] = useState({
    "domaines":[
      {value :"Donation"},
      {value :"Succession"},
      {value :"Immobilier"},
      {value :"Entreprise"},
      {value :"Famille"},
    ],
    "langues":[ 
      {value :"Français"},
      {value :"Anglais"},
      {value :"Allemand"},
      {value :"Arabe"},
      {value :"Italien"},
      {value :"Chinois"},
      {value :"Hébreu"},
      {value :"Espagnol"},
      {value :"Portugais"},
      {value :"Russe"},
    ],
    "accessibilites":[
      {value :"Accès personne handicapée"},
      {value :"Ascenseur"},
      {value :"Parking"},
    ],
  });
  const [paginateData, setPaginateData] = useState({
    "current": 1,
    "total_pages": 0,
    "total_results": 0,
    "size": 100
  });
  const [geoJson, setGeoJson] = useState({
    "type": "FeatureCollection",
    "features": []
  });
  const [latlng, setLatlng] = useState({
    "latitude":48.864716,
    "longitude":2.349014,
  });
  const [breadcrumbsData, setBreadcrumbsData] = useState({
    "breadCrumbs":[
      {"pagename":"Notaires","path":"/notaires"}
    ],
    "main":{"pagename":postalCode,"path":"/"}
  });
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    // setPageCount(Math.ceil(data.length / itemPerPage));
    setPageCount(Math.ceil(paginateData.total_results / itemPerPage))
  }, [postalCode, itemOffset, data,paginateData]);
  // Use Effect
  useEffect(() => {
    setBreadcrumbsData({
      "breadCrumbs":[
        {"pagename":"Notaires","path":"/notaires"}
      ],
      "main":{"pagename":postalCode,"path":"/"}
    })
    setLoadingFullpage(true);   
    let URL = process.env.REACT_APP_API_URL + "api/admin/search_directive_notaires";    
    let data = { search: postalCode,size:paginateData.size,currentPage:paginateData.current  };


    let config = {
      method: "post",
      url: URL,
      data: data,
      headers: {
        // 'Authorization': `Bearer ${process.env.REACT_APP_ELK_SEARCH_API_KEY}`,
        // 'Accept': '*/*', 
        // "Cache-Control":"no-cache",
        'Content-Type': 'application/json',
      },
    };

    axios
      .request(config)
      .then(async(response) => {
        if(response.data && response.data.data && response.data.data.results.length>0){
          let jsonData = [];
          await Promise.all(response.data.data.results.map(async(val,key)=>{            
            try {            
              const responseAddress = await getLatLngFromAddress(val.locality.raw);              
              if(responseAddress && responseAddress.features.length>0){
                let filterData  = responseAddress.features.filter((Filterval,i)=>Filterval.place_name.includes(val.postal_code.raw))
                // console.log("filterData",filterData);
                if(filterData.length>0){
                  jsonData.push({
                    "type": "Feature",
                    "geometry": filterData[0].geometry
                  })
                  setLatlng({
                    "latitude":filterData[0].geometry.coordinates[1],
                    "longitude":filterData[0].geometry.coordinates[0],
                  })
                }
              }  
            } catch (err) {
              // throw new Error('Unable to establish a login session.');
            }
                      
          }))
          setGeoJson({
            "type": "FeatureCollection",
            "features": jsonData
          });
        }
        setData(response.data.data.results);
        setPaginateData(response.data.data.meta.page);
        setLoadingFullpage(false);
      })
      .catch((error) => {
        setLoadingFullpage(false);
        console.log(error);
      });
  }, [postalCode]);

  const getSearchResult=(currentPage)=>{
    setLoadingFullpage(true);   
    let URL = process.env.REACT_APP_API_URL + "api/admin/search_directive_notaires";    
    let data = { search: postalCode,size:paginateData.size,currentPage:paginateData.current  };
    let config = {
      method: "post",
      url: URL,
      data: data,
      headers: { 
        // 'Authorization': `Bearer ${process.env.REACT_APP_ELK_SEARCH_API_KEY}`,
        // 'Accept': '*/*', 
        // "Cache-Control":"no-cache",
        'Content-Type': 'application/json', 
      },
    };

    axios
      .request(config)
      .then(async(response) => {
        if(response.data && response.data.data && response.data.data.results.length>0){
          let jsonData = [];
          await Promise.all(response.data.data.results.map(async(val,key)=>{            
            try {            
              const responseAddress = await getLatLngFromAddress(val.locality.raw);              
              if(responseAddress && responseAddress.features.length>0){
                let filterData  = responseAddress.features.filter((Filterval,i)=>Filterval.place_name.includes(val.postal_code.raw))
                // console.log("filterData",filterData);
                if(filterData.length>0){
                  jsonData.push({
                    "type": "Feature",
                    "geometry": filterData[0].geometry
                  })
                  setLatlng({
                    "latitude":filterData[0].geometry.coordinates[1],
                    "longitude":filterData[0].geometry.coordinates[0],
                  })
                }
              }  
            } catch (err) {
              // throw new Error('Unable to establish a login session.');
            }
                      
          }))
          setGeoJson({
            "type": "FeatureCollection",
            "features": jsonData
          });
        }
        setData(response.data.data.results);
        setPaginateData(response.data.data.meta.page);
        setLoadingFullpage(false);
        
      })
      .catch((error) => {
        setLoadingFullpage(false);
        console.log(error);
      });
  }
  // ====================================
  
  const [deCriteres, setDeCriteres] = useState(true);
  const onClick = () => {
    setDeCriteres(!deCriteres);
  };
  // ================================================
  /* =====================|Get Default Values|================== */
  const searchRef = useRef(null);
  const [search, setSearch] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  // Search Input data
  function getInputSearchData(input) {
    setSearchKey(input);
    if (input) {
      /* let data = JSON.stringify({
        "query": input,
        "group": {
          "field": "postal_code"
        }
      }); */
      let data = { search: input,size:10,currentPage:1  }
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_API_URL + `api/admin/search_directive_notaires`,
        headers: {
          // 'Authorization': `Bearer ${process.env.REACT_APP_ELK_SEARCH_API_KEY}`,
          // 'Accept': '*/*', 
          // "Cache-Control":"no-cache",
          'Content-Type': 'application/json',
        },
        data: data
      };
      axios.request(config)
        .then((response) => {

          if ((response.data.data.results).length > 0) {
            let resultList = response.data.data.results.filter((val, i) => val.locality.raw && val.postal_code.raw)
            setSearch(resultList);
            setCheckList(true);
          } else {
            setSearch(response.data.data.results);
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error(error);
          setCheckList(false);
        });
      
    } else {
      setCheckList(false);
    }
  }
  // ========================================
  const [checkList, setCheckList] = useState(false);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [search, checkList]);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setCheckList(false);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [singleList, setSingleList] = useState(null);

  const handleOpenMessage = (event,list) => {
    // console.log("handleOpenMessage")
    setIsOpen(true)
    setSingleList(list)
  };
  // ==================filter==========================
  
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedFilterData({...selectedFilterData,
      name:typeof value === 'string' ? value.split(',') : value,
    })
   /*  setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    ); */
  };
  // ======================|pagination|=========================
  function Items({ currentItems }) {
    return (
      <div className="items mb-2">
        {currentItems &&
          currentItems.map((list) => {
            let cleanedStr = "";
            let array = [];
            let url = no_image;
            if (list.logo && list.logo.raw) {
              cleanedStr = list.logo.raw.replace(/^\[('|")?|('|")?\]$/g, "");
              array = cleanedStr.split(",");
              url = array[0].trim();
            }

            return (
              <div className="btnShadow lg:max-w-[723px] w-full h-auto py-8 px-4 bg-white rounded-[21px] mt-8">
                <Link
                  to={"/fiche-notaires/"+decodedStringValue(list.slug)+"/" + list.notaires_id.raw}
                  className="flex sm:flex-row flex-col items-center gap-4"
                >
                  <img src={url} alt={url} style={{"width":"80px"}} />
                  <div className="flex items-start sm:flex-row flex-col justify-between w-full">
                    <div>
                      {/* <h4 className="font-bold text-lg 3xl:text-xl text-primary">
                        {list.office_address.raw}
                      </h4> */}
                      <h6 className="text-base font-semibold text-primary">
                      {decodedStringValue(list.name.raw)}
                      </h6>
                      <p className="text-base font-light text-primary">
                        Notaire
                      </p>
                    </div>

                    <div className="flex whitespace-nowrap items-center gap-1 mt-4 sm:my-0">
                      <FiMapPin />
                      <p>
                      {list.postal_code.raw}, {decodedStringValue(list.locality.raw)} 
                      </p>
                    </div>
                  </div>
                </Link>

                <hr className="my-6 border-black-light opacity-50" />
                      {/* display when compleete his form */}
                <div>
                  <div className="flex gap-2 mt-4">
                    <img src={Compétences} alt="" />
                    <p className="text-base font-normal text-primary">
                      Domaines
                    </p>
                  </div>
                  <div className="mt-4 flex gap-2 items-center flex-wrap  ">
                    {/* <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                      Donation{" "}
                    </div>
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                      Succession{" "}
                    </div>
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                      Immobilier{" "}
                    </div>
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                     Entreprise
                    </div>
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                     Famille
                    </div> */}
                  </div>
                </div>

               
                <div className="flex justify-between items-center mt-8 gap-1 sm:gap-4">
                  <button className="w-full max-w-[252px] h-full min-h-[30px] sm:min-h-[50px] flex items-center justify-center gap-1 sm:gap-3 bg-primary text-white  text-xs sm:text-base font-semibold rounded-md transition-all duration-300 hover:bg-secondary" onClick={(e)=>handleOpenMessage(e,list)}>
                    <img
                      src={messages}
                      alt="messages"
                      className="w-4 h-4 sm:w-auto sm:h-auto"
                    />
                    Message
                  </button>
                  <button className="w-full max-w-[252px] h-full  min-h-[30px] sm:min-h-[50px] flex items-center justify-center gap-1 sm:gap-3 bg-primary text-white text-xs sm:text-base font-semibold rounded-md transition-all duration-300 hover:bg-secondary">
                    <img
                      src={tel}
                      alt="messages"
                      className="w-4 h-4 sm:w-auto sm:h-auto rotate_telephone"
                    />
                    Contacter
                  </button>
                  {/* <button className="w-full max-w-[252px] h-full  min-h-[30px] sm:min-h-[50px] flex items-center justify-center gap-1 sm:gap-3 bg-primary text-white text-xs sm:text-base font-semibold rounded-md transition-all duration-300 hover:bg-secondary">
                    <img
                      src={akarIconsCalendar}
                      alt="akarIconsCalendar"
                      className="w-4 h-4 sm:w-auto sm:h-auto"
                    />
                    Prendre RDV
                  </button> */}
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * 4) % data.length;
    setItemOffset(newOffset);
    setPaginateData(paginateData => ({ ...paginateData, ["current"]: event.selected+1 }));
    getSearchResult(event.selected+1 );
    /* setPaginateData({
      "current": event.selected+1,
      "total_pages": 0,
      "total_results": 0,
      "size": 10
    }) */
  };
  return (
    <>
      <MessageProFormComp setSuccessfull={setSuccessfull} isOpen={isOpen} setIsOpen={setIsOpen} singleList={singleList} type={"notaires"} />
      {loadingFullpage?
      <PageLoaderComp />
      :""}
      {successfull?
        <ToastMessageComp message={alert.message} type={alert.type} />
        :""}
      <div className="max-w-[1277px] m-auto my-8 md:mt-5 flex justify-center items-center flex-col gap-4">
        <div className="flex items-center justify-start sm:gap-4 w-full m-auto">
          <Breadcrumb page="Experts-comptables" breadcrumbsData={breadcrumbsData} />
        </div>
        <div
          style={{
            position: "relative",
          }}
        >
          <form
            id="searchForm"
            className="bg-white border-0 max-w-[727px] sm:mx-auto my-6 px-5 py-3 sm:py-6 input-box-shadow rounded flex justify-between w-full"
            style={{ boxShadow: "0px 2px 12px rgba(83, 83, 83, 0.25)" ,zIndex:"9" }}
          >
            <input
              type="text"
              onInput={(e) => getInputSearchData(e.target.value)}
              defaultValue={postalCode}
              className=" w-full outline-none text-[#797979] text-base font-light"
              placeholder="Nom de la ville ou du département"
            />
            <button>
              <img src={searchIcon} alt="searchIcon" />
            </button>
          </form>
          <div
            style={{
              display: checkList ? "block" : "none",
              left: "0",
              right: "0",
              top: "80px",zIndex:"9"
            }}
            className="searchListBox max-w-[727px] sm:mx-auto"
            ref={searchRef}
          >
            <ul>
              {search.length === 0 ? (
                <div
                  className="containertype"
                  style={{ textAlign: "left", paddingTop:"9px 19px" }}
                >
                  <span>No result found!</span>
                  {/* <div className="dottype"></div>
                  <div className="dottype"></div>
                  <div className="dottype"></div> */}
                </div>
              ) : (
                ""
              )}
              {search.map((list) => {
                return (
                  <li>
                    <Link
                      className="ctmref"
                      to={"/fiche-notaires/"+decodedStringValue(list.slug)+"/" + list.notaires_id.raw}
                    >
                      {decodedStringValue(list.name.raw)} ({list.postal_code.raw}, {decodedStringValue(list.locality.raw)} )
                    </Link>
                  </li>
                );
              })}
              <li className={search.length>0?"":"hidden"} style={{"fontWeight":"700"}}>
                <Link
                  onClick={(e)=>{
                    setCheckList(false)
                    setPaginateData({
                      "current": 1,
                      "total_pages": 0,
                      "total_results": 0,
                      "size": 100
                    })
                  }}
                  className="ctmref"
                  // to={`/directory_search_result/notaires/${searchKey}`}
                  to={`/annuaire-des-notaires-selection/notaires/${searchKey}`}
                >
                  {"Voir tous les résultats de la recherche"}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="max-w-[627px] pb-6 m-auto">
          <div className="flex items-center justify-center w-full gap-2">
            <div className="h-[0.5px] bg-[#DEE3E2] block w-full"> </div>
            <p
              className=" font-medium text-lg text-primary min-w-[120px] m-auto text-center cursor-pointer"
              onClick={onClick}
            >
              {" "}
              + de critères
            </p>
            <div className="h-[0.5px] bg-[#DEE3E2] block w-full"> </div>
          </div>
          {deCriteres ? (
            <div className="flex sm:items-center flex-wrap sm:justify-center gap-2 sm:gap-6 mt-5 transition-all duration-300">   
            <FormControl sx={{ m: 1 ,width:"150px" }}>
                <InputLabel id="demo-multiple-checkbox-label">Langues</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  name="langues"
                  value={selectedFilterData.langues}
                  onChange={handleChange}
                  input={<OutlinedInput label="Langues" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                   {filterData.langues.map((name) => (
                    <MenuItem key={name.value} value={name.value}>
                      <Checkbox checked={selectedFilterData.langues.indexOf(name.value) > -1}  />
                      <ListItemText primary={name.value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1  ,width:"150px"}}>
                <InputLabel id="demo-multiple-checkbox-label">Accessibilités</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedFilterData.accessibilites}
                  name="accessibilites"
                  onChange={handleChange}
                  input={<OutlinedInput label="Accessibilités" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {filterData.accessibilites.map((name) => (
                    <MenuItem key={name.value} value={name.value}>
                      <Checkbox  checked={selectedFilterData.accessibilites.indexOf(name.value) > -1} />
                      <ListItemText primary={name.value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>           
             {/*  <button className="text-lg font-light text-primary py-2 px-2 sm:px-5 border rounded-md border-primary  hover:bg-secondary hover:border-secondary hover:text-white">
                Langues
              </button>
              <button className="text-lg font-light text-primary py-2 px-2 sm:px-5 border rounded-md border-primary hover:bg-secondary hover:border-secondary hover:text-white">
                Accessibilités
              </button> */}
            </div>
          ) : null}
        </div>
      </div>
      <div className="w-full bg-mainBg ">
        <div className="grid grid-cols-12 ">
          <div className="col-span-12 lg:col-span-6 3xl:col-span-6 px-4 py-10 h-screen  overflow-auto">
            <h5 className="text-lg font-normal text-primary">
              <span className="font-semibold">({paginateData.total_results})</span> Résultat pour notaires{" "} 
              <span className="font-semibold">{postalCode}</span>
            </h5>
            {/* ============================================== */}
            {/* ============================================== */}
            {/* {data.map((list) => {
              const cleanedStr = list.logo.replace(/^\[('|")?|('|")?\]$/g, "");
              const array = cleanedStr.split(",");
              const url = array[0].trim();
              return (
                <div className="btnShadow lg:max-w-[723px] w-full h-auto py-8 px-4 bg-white rounded-[21px] mt-8">
                  <Link
                    to={"/fiche-avocats/" + list.id}
                    className="flex sm:flex-row flex-col items-center gap-4"
                  >
                    <img src={url} alt="FridLogo" />
                    <div className="flex items-start sm:flex-row flex-col justify-between w-full">
                      <div>
                        <h4 className="font-bold text-lg 3xl:text-xl text-primary">
                          {list.name}
                        </h4>
                        <h6 className="text-base font-semibold text-primary">
                          Cabinet comptable
                        </h6>
                        <p className="text-base font-light text-primary">
                          Expert-comptable - Commissaire aux comptes
                        </p>
                      </div>

                      <div className="flex whitespace-nowrap items-center gap-1 mt-4 sm:my-0">
                        <FiMapPin />
                        <p>
                          {list.addressLocality} {list.postalCode}
                        </p>
                      </div>
                    </div>
                  </Link>

                  <hr className="my-6 border-black-light opacity-50" />

                  <div>
                    <div className="flex gap-2 mt-4">
                      <img src={Compétences} alt="" />
                      <p className="text-base font-normal text-primary">
                        Compétences
                      </p>
                    </div>
                    <div className="mt-4 flex gap-2 items-center flex-wrap  ">
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        Gestion de la paie{" "}
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        Commissaire à la fusion{" "}
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        Commissaire à la fusion{" "}
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        + 5
                      </div>
                    </div>
                  </div>

                  <div className="mt-8">
                    <div className="flex gap-2 mt-4">
                      <img src={Compétences} alt="" />
                      <p className="text-base font-normal text-primary">
                        Secteurs
                      </p>
                    </div>
                    <div className="mt-4 flex gap-2 items-center flex-wrap">
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        TPE -PME -ETI{" "}
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        Profession libérale{" "}
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        Commerce
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        E-Commerce
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        + 3
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between items-center mt-8 gap-1 sm:gap-4">
                    <button className="w-full max-w-[252px] h-full min-h-[30px] sm:min-h-[50px] flex items-center justify-center gap-1 sm:gap-3 bg-primary text-white  text-xs sm:text-base font-semibold rounded-md transition-all duration-300 hover:bg-secondary">
                      <img
                        src={messages}
                        alt="messages"
                        className="w-4 h-4 sm:w-auto sm:h-auto"
                      />
                      Message
                    </button>
                    <button className="w-full max-w-[252px] h-full  min-h-[30px] sm:min-h-[50px] flex items-center justify-center gap-1 sm:gap-3 bg-primary text-white text-xs sm:text-base font-semibold rounded-md transition-all duration-300 hover:bg-secondary">
                      <img
                        src={tel}
                        alt="messages"
                        className="w-4 h-4 sm:w-auto sm:h-auto rotate_telephone"
                      />
                      Contacter
                    </button>
                    <button className="w-full max-w-[252px] h-full  min-h-[30px] sm:min-h-[50px] flex items-center justify-center gap-1 sm:gap-3 bg-primary text-white text-xs sm:text-base font-semibold rounded-md transition-all duration-300 hover:bg-secondary">
                      <img
                        src={akarIconsCalendar}
                        alt="akarIconsCalendar"
                        className="w-4 h-4 sm:w-auto sm:h-auto"
                      />
                      Prendre RDV
                    </button>
                  </div>
                </div>
              );
            })} */}
            {/* ====================================================== */}
            <Items currentItems={currentItems} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: " center",
              }}
            >
              <ReactPaginate
                nextLabel="next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
            {/* <nav
              aria-label="Page navigation example"
              className="mt-8 mx-auto w-full flex items-center justify-between"
            >
              <Link
                to=""
                className="flex items-center justify-center gap-2 text-xs sm:text-base font-medium text-gary bg-white py-2 px-3 rounded-md hover:bg-primary hover:text-white"
                style={{ boxShadow: "(0px 4px 2px rgba(240, 240, 240, 0.25)" }}
              >
                <IoIosArrowBack /> Précédent
              </Link>
              <ul className="inline-flex  space-x-3">
                <li>
                  <Link
                    to=""
                    className="text-base font-medium w-9 h-9 rounded-md flex items-center justify-center bg-primary text-white"
                  >
                    1
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    className="text-base font-medium w-9 h-9 bg-white rounded-md flex items-center justify-center text-primary hover:bg-primary hover:text-white"
                  >
                    2
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    className="text-base font-medium w-9 h-9 bg-white rounded-md flex items-center justify-center text-primary hover:bg-primary hover:text-white"
                  >
                    3
                  </Link>
                </li>
                <li className="hidden sm:block">
                  <Link
                    to=""
                    className="text-base font-medium w-9 h-9 bg-white rounded-md flex items-center justify-center text-primary hover:bg-primary hover:text-white"
                  >
                    4
                  </Link>
                </li>
              </ul>
              <Link
                to=""
                className="flex items-center justify-center gap-2 text-xs sm:text-base font-medium text-gary bg-white py-2 px-3 rounded-md hover:bg-primary hover:text-white"
                style={{ boxShadow: "(0px 4px 2px rgba(240, 240, 240, 0.25)" }}
              >
                Suivant
                <IoIosArrowForward />{" "}
              </Link>
            </nav> */}
          </div>
          <div className="col-span-12 lg:col-span-6 3xl:col-span-6">
            {/* <img src={mapDemo} alt="mapDemo" className='h-screen w-full' /> */}
            <Mapbox  geoJson={geoJson} latitude={latlng.latitude} longitude={latlng.longitude} />
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5666151.454104781!2d-3.2305647495523675!3d46.09744499642553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd54a02933785731%3A0x6bfd3f96c747d9f7!2sFrance!5e0!3m2!1sen!2sin!4v1685359635891!5m2!1sen!2sin"
              style={{ width: "100%", height: "100%", minHeight: "785px" }}
              title="france"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
