import moment from "moment";
import frLocale from "moment/locale/fr";
import axios from "axios";
export const changeDateFormatFrench =(date)=>{
    moment.locale('fr', [frLocale])
    
    return moment(date).format('LL');
}

export const changeDateFormatFrenchDynamic =(date,dateformat='LL')=>{
    moment.locale('fr', [frLocale])
    
    return moment(date).format(dateformat);
}
export const convertNumberFrench =(get_number)=>{       
    const number = Number(get_number);
    return number.toLocaleString("fr-FR");
}
export const isValidEmail =(email)=>{ 
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

export const isAlphabetic =(inputVal)=>{ 
    const regex = /^[A-Za-z\s]+$/;
    return (typeof inputVal === "string" && inputVal.length>0 && regex.test(inputVal));
}

export const isNumericValue =(value)=>{ 
    return value.length>0 && /^\d*$/.test(value);
}


export const getDetailsBySiren =async(sirenNo)=>{
    try {       
        const fetchResponse = await axios.get(`${process.env.REACT_APP_API_URL}api/announce/sirenDetails/${sirenNo}`);       
        if(fetchResponse){
            return fetchResponse.data.data;

        }else{
            return {
                siren:"", denomination:"", forme_juridique:"",capital_social:"",adresse_rcs:"", code_postal:"",ville:"", area:"",
            };
        }
    } catch (e) {
        // return e;
        return {
            siren:"", denomination:"", forme_juridique:"",capital_social:"",adresse_rcs:"", code_postal:"",ville:"", area:"",
        };
    } 
    
}
export const decodedStringValue =(value)=>{ 
    let encodedString = value;
    let div = document.createElement('div');
    div.innerHTML = encodedString;
    let decodedString = div.innerText || div.textContent;
    return decodedString;
}
export const getMonthsStartingWithCurrent = () => {
    const monthNames = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
  
    const currentMonthIndex = new Date().getMonth();
    const monthsStartingWithCurrent = [
      ...monthNames.slice(currentMonthIndex),
      ...monthNames.slice(0, currentMonthIndex)
    ];
  
    return monthsStartingWithCurrent;
  };
