import React, { useState, useEffect } from "react";
import { NavLink, Link, useOutletContext } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { alertActions, userActions } from "../_actions";
import Avatar from "@mui/material/Avatar";

export default function SideBar() {
  const user = useSelector((state) => state.authentication.user);
  const [menuOpen, hendlemenuOpen, setMenuOpen] = useOutletContext();


  return (
    <>
      <div className={`xl:max-w-[327px] max-w-full xl:relative   w-full xl:max-h-[760px] h-full xl:pb-14 bg-white rounded-[20px] sideShadow   ${menuOpen ? "transition ease-in-out delay-1500  absolute left-0 right-0 top-0 xl:z-[9] z-[999] xl-h-full h-[100vh]" : "xl:block hidden transition ease-in-out delay-1500  "}`} >
        <div className="flex items-center xl:justify-center justify-between px-4 py-3">
          <Link to={"/profile"}  onClick={() => setMenuOpen(false)}>
            <div className="flex  xl:flex-col flex-row justify-center items-center gap-2 xl:pt-8 pt-0">
              {user && user.data && user.data.profile_image ? (
                <Avatar
                  alt={user.data.profile_image}
                  src={
                    user.data.login_type === "google" ||
                      user.data.login_type === "facebook"
                      ? user.data.profile_image
                      : process.env.REACT_APP_API_URL +
                      "uploads/" +
                      user.data.profile_image
                  }
                  sx={{ width: 61, height: 61 }}
                />
              ) : (
                <svg
                  // width="61"
                  // height="61"
                  className="xl:w-[61px] w-[40px] xl:h-[61] h-[40] "
                  viewBox="0 0 61 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M30.5 0C13.664 0 0 13.664 0 30.5C0 47.336 13.664 61 30.5 61C47.336 61 61 47.336 61 30.5C61 13.664 47.336 0 30.5 0ZM30.5 12.2C36.3865 12.2 41.175 16.9885 41.175 22.875C41.175 28.7615 36.3865 33.55 30.5 33.55C24.6135 33.55 19.825 28.7615 19.825 22.875C19.825 16.9885 24.6135 12.2 30.5 12.2ZM30.5 54.9C24.3085 54.9 16.9885 52.399 11.773 46.116C17.1154 41.9244 23.7095 39.6464 30.5 39.6464C37.2905 39.6464 43.8846 41.9244 49.227 46.116C44.0115 52.399 36.6915 54.9 30.5 54.9Z"
                    fill="#DEE3E2"
                  />
                </svg>
              )}
              <h3 className="text-base text-primary font-semibold">
                {user && user.data
                  ? user.data.firstname + " " + user.data.lastname
                  : " "}
              </h3>
            </div>
          </Link>
          
        </div>

        <div className={`xl:mt-2 mt-3 `}>
          <ul>
            <NavLink
              onClick={() => setMenuOpen(false)}
              to="/dashboard"
              className="mainLi flex items-center gap-5 my-2 py-4 mr-4 rounded-e-full pl-7 text-black-light"
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.91667 13.1667H1.41667C1.30616 13.1667 1.20018 13.1228 1.12204 13.0446C1.0439 12.9665 1 12.8605 1 12.75V1.41667C1 1.30616 1.0439 1.20018 1.12204 1.12204C1.20018 1.0439 1.30616 1 1.41667 1H9.91667C10.0272 1 10.1332 1.0439 10.2113 1.12204C10.2894 1.20018 10.3333 1.30616 10.3333 1.41667V12.75C10.3333 12.8605 10.2894 12.9665 10.2113 13.0446C10.1332 13.1228 10.0272 13.1667 9.91667 13.1667ZM1.12204 24.378C1.0439 24.2998 1 24.1938 1 24.0833V18.4167C1 18.3062 1.0439 18.2002 1.12204 18.122C1.20018 18.0439 1.30616 18 1.41667 18H9.91667C10.0272 18 10.1332 18.0439 10.2113 18.122C10.2894 18.2002 10.3333 18.3062 10.3333 18.4167V24.0833C10.3333 24.1938 10.2894 24.2998 10.2113 24.378C10.1332 24.4561 10.0272 24.5 9.91667 24.5H1.41667C1.30616 24.5 1.20018 24.4561 1.12204 24.378ZM15.2887 24.378C15.2106 24.2998 15.1667 24.1938 15.1667 24.0833V14.1667C15.1667 14.0562 15.2106 13.9502 15.2887 13.872C15.3668 13.7939 15.4728 13.75 15.5833 13.75H24.0833C24.1938 13.75 24.2998 13.7939 24.378 13.872C24.4561 13.9502 24.5 14.0562 24.5 14.1667V24.0833C24.5 24.1938 24.4561 24.2998 24.378 24.378C24.2998 24.4561 24.1938 24.5 24.0833 24.5H15.5833C15.4728 24.5 15.3668 24.4561 15.2887 24.378ZM24.0833 8.91667H15.5833C15.4728 8.91667 15.3668 8.87277 15.2887 8.79463C15.2106 8.71649 15.1667 8.61051 15.1667 8.5V1.41667C15.1667 1.30616 15.2106 1.20018 15.2887 1.12204C15.3668 1.0439 15.4728 1 15.5833 1H24.0833C24.1938 1 24.2998 1.0439 24.378 1.12204C24.4561 1.20018 24.5 1.30616 24.5 1.41667V8.5C24.5 8.61051 24.4561 8.71649 24.378 8.79463C24.2998 8.87277 24.1938 8.91667 24.0833 8.91667Z"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
              <span className="text-base font-medium">Tableau de bord</span>
            </NavLink>
            <NavLink
              onClick={() => setMenuOpen(false)}
              to="/userdashboard/annonces-en-attente"
              className="mainLi flex items-center gap-5 my-2 py-4 mr-4 rounded-e-full pl-7 cursor-pointer text-black-light"
            >
              <svg
                width="27"
                height="33"
                viewBox="0 0 27 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 5.14282V25.8473"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M23.3076 11.3573V25.8574"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.71826 1H14.0131"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.71826 30.0001H19.59"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M23.3078 25.8575C23.3179 27.9266 21.4488 30.0004 19.5898 30.0004"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.00033 25.8575C1.00033 27.929 2.85929 30.0004 4.71826 30.0004"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.00033 5.14001C1.00033 3.07158 2.8593 1.04628 4.71826 1.00015"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.2974 11.3665L14.0127 1"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.0125 7.21448C14.0183 9.27464 15.879 11.3573 17.7305 11.3573"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.0127 7.21431V1"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.7305 11.3573H23.3074"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.71826 25.8575H10.2952"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.71826 21.7146H14.0131"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.71826 17.5718H10.2952"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle
                  cx="19.1063"
                  cy="25.1063"
                  r="5.60631"
                  stroke="currentColor"
                  strokeWidth="3"
                />
                <path
                  d="M19.1065 31.0285C22.3771 31.0285 25.0284 28.3772 25.0284 25.1066C25.0284 21.836 22.3771 19.1847 19.1065 19.1847C15.8359 19.1847 13.1846 21.836 13.1846 25.1066C13.1846 28.3772 15.8359 31.0285 19.1065 31.0285Z"
                  fill="white"
                />
                <path
                  d="M20.883 27.4756L19.4535 26.0461C19.2313 25.824 19.1065 25.5228 19.1064 25.2087V21.5537"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="text-base font-medium">Annonces en attente</span>
            </NavLink>
            <NavLink
              onClick={() => setMenuOpen(false)}
              to="/userdashboard/annonces-publiees"
              className="mainLi group flex items-center gap-5 my-2 py-4 mr-4 rounded-e-full pl-7 cursor-pointer  text-black-light"
            >
              <svg
                width="25"
                height="31"
                viewBox="0 0 25 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 5.14282V25.8473"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M23.3076 11.3573V25.8574"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.71826 1H14.0131"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.71826 30.0001H19.59"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M23.3078 25.8575C23.3179 27.9266 21.4488 30.0004 19.5898 30.0004"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.00033 25.8575C1.00033 27.929 2.85929 30.0004 4.71826 30.0004"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.00033 5.14001C1.00033 3.07158 2.8593 1.04628 4.71826 1.00015"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.2974 11.3665L14.0127 1"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.0125 7.21448C14.0183 9.27464 15.879 11.3573 17.7305 11.3573"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.0127 7.21431V1"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.7305 11.3573H23.3074"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.71826 25.8575H10.2952"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.71826 21.7146H14.0131"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.71826 17.5718H10.2952"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="text-base font-medium">Annonces publiées</span>
            </NavLink>

            <NavLink
              onClick={() => setMenuOpen(false)}
              to="/userdashboard/factures"
              className="mainLi flex items-center gap-5 my-2 py-4 mr-4 rounded-e-full pl-7 cursor-pointer text-black-light"
            >
              <svg
                width="23"
                height="31"
                viewBox="0 0 23 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask id="path-1-inside-1_3633_6829" fill="white">
                  <path d="M6.19231 14.4563H5.30769V16.523H6.19231V14.4563ZM16.8077 16.523H17.6923V14.4563H16.8077V16.523ZM6.19231 19.6333H5.30769V20.6667H6.19231V19.6333ZM16.8077 19.6333V20.6667H17.6923V19.6333H16.8077ZM6.19231 11.3667V10.3333H5.30769V11.3667H6.19231ZM16.8077 11.3667H17.6923V10.3333H16.8077V11.3667ZM22.1154 7.23333H23V6.80553L22.7417 6.50173L22.1154 7.23333V7.23333ZM16.8077 1.03333L17.434 0.301733L17.1739 0H16.8077V1.03333ZM6.19231 16.523H16.8077V14.4563H6.19231V16.523ZM6.19231 20.6667H16.8077V18.6H6.19231V20.6667ZM6.19231 12.4H16.8077V10.3333H6.19231V12.4ZM20.3462 28.9333H2.65385V31H20.3462V28.9333ZM1.76923 27.9V3.1H0V27.9H1.76923ZM21.2308 7.23333V27.9H23V7.23333H21.2308ZM2.65385 2.06667H16.8077V0H2.65385V2.06667ZM16.1814 1.76493L21.4891 7.96493L22.7417 6.50173L17.434 0.301733L16.1814 1.76493V1.76493ZM2.65385 28.9333C2.41923 28.9333 2.19423 28.8245 2.02833 28.6307C1.86243 28.4369 1.76923 28.1741 1.76923 27.9H0C0 28.7222 0.279601 29.5107 0.777294 30.092C1.27499 30.6734 1.95 31 2.65385 31V28.9333ZM20.3462 31C21.05 31 21.725 30.6734 22.2227 30.092C22.7204 29.5107 23 28.7222 23 27.9H21.2308C21.2308 28.1741 21.1376 28.4369 20.9717 28.6307C20.8058 28.8245 20.5808 28.9333 20.3462 28.9333V31ZM1.76923 3.1C1.76923 2.82594 1.86243 2.56311 2.02833 2.36932C2.19423 2.17554 2.41923 2.06667 2.65385 2.06667V0C1.95 0 1.27499 0.326606 0.777294 0.907969C0.279601 1.48933 0 2.27783 0 3.1H1.76923ZM5.30769 11.3667V19.6333H7.07692V11.3667H5.30769ZM10.6154 11.3667V19.6333H12.3846V11.3667H10.6154ZM15.9231 11.3667V19.6333H17.6923V11.3667H15.9231ZM5.30769 8.26667H10.6154V6.2H5.30769V8.26667ZM12.3846 24.8H17.6923V22.7333H12.3846V24.8Z" />
                </mask>
                <path
                  d="M6.19231 14.4563H5.30769V16.523H6.19231V14.4563ZM16.8077 16.523H17.6923V14.4563H16.8077V16.523ZM6.19231 19.6333H5.30769V20.6667H6.19231V19.6333ZM16.8077 19.6333V20.6667H17.6923V19.6333H16.8077ZM6.19231 11.3667V10.3333H5.30769V11.3667H6.19231ZM16.8077 11.3667H17.6923V10.3333H16.8077V11.3667ZM22.1154 7.23333H23V6.80553L22.7417 6.50173L22.1154 7.23333V7.23333ZM16.8077 1.03333L17.434 0.301733L17.1739 0H16.8077V1.03333ZM6.19231 16.523H16.8077V14.4563H6.19231V16.523ZM6.19231 20.6667H16.8077V18.6H6.19231V20.6667ZM6.19231 12.4H16.8077V10.3333H6.19231V12.4ZM20.3462 28.9333H2.65385V31H20.3462V28.9333ZM1.76923 27.9V3.1H0V27.9H1.76923ZM21.2308 7.23333V27.9H23V7.23333H21.2308ZM2.65385 2.06667H16.8077V0H2.65385V2.06667ZM16.1814 1.76493L21.4891 7.96493L22.7417 6.50173L17.434 0.301733L16.1814 1.76493V1.76493ZM2.65385 28.9333C2.41923 28.9333 2.19423 28.8245 2.02833 28.6307C1.86243 28.4369 1.76923 28.1741 1.76923 27.9H0C0 28.7222 0.279601 29.5107 0.777294 30.092C1.27499 30.6734 1.95 31 2.65385 31V28.9333ZM20.3462 31C21.05 31 21.725 30.6734 22.2227 30.092C22.7204 29.5107 23 28.7222 23 27.9H21.2308C21.2308 28.1741 21.1376 28.4369 20.9717 28.6307C20.8058 28.8245 20.5808 28.9333 20.3462 28.9333V31ZM1.76923 3.1C1.76923 2.82594 1.86243 2.56311 2.02833 2.36932C2.19423 2.17554 2.41923 2.06667 2.65385 2.06667V0C1.95 0 1.27499 0.326606 0.777294 0.907969C0.279601 1.48933 0 2.27783 0 3.1H1.76923ZM5.30769 11.3667V19.6333H7.07692V11.3667H5.30769ZM10.6154 11.3667V19.6333H12.3846V11.3667H10.6154ZM15.9231 11.3667V19.6333H17.6923V11.3667H15.9231ZM5.30769 8.26667H10.6154V6.2H5.30769V8.26667ZM12.3846 24.8H17.6923V22.7333H12.3846V24.8Z"
                  fill="cuttentcolor"
                  stroke="currentColor"
                  strokeWidth="2"
                  mask="url(#path-1-inside-1_3633_6829)"
                />
              </svg>

              <span className="text-base font-medium">Factures</span>
            </NavLink>
          </ul>
        </div>
      </div>
    </>
  );
}
