/* eslint-disable react-hooks/rules-of-hooks */
import React,{useState,useEffect} from 'react'
import Sidebar from './component/Sidebar'
import axios from 'axios'
import { Typography } from '@mui/material'; 
import { FormControl, InputLabel, MenuItem, Select, TextField, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate,useLocation,Navigate } from 'react-router-dom'
import ToastMessageComp from '../component/ToastMessageComp';
import { adminActions } from '../_actions';
import { alertActions } from '../_actions';

export default function profile() {
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch(); 
  
  const currentAdmin = useSelector(state => state.administrator.admin);
  const [submitted, setsubmitted] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [passwordsubmitted, setPasswordsubmitted] = useState(false);
  
  const [profileData, setProfileData] = useState({
    "id":"",
    "username":"",
    "email":"",
    "firstname":"",
    "lastname":"",
    "contact":"",
  });
  const [changePasswordData, setChangePasswordData] = useState({
    id: currentAdmin&&currentAdmin.data?currentAdmin.data.id:"",
    password: "",
    confirm_password: "",
  });
  useEffect(() => {
    if (currentAdmin) {
      setProfileData({
        "id":currentAdmin && currentAdmin.data?currentAdmin.data.id:"",
        "username":currentAdmin && currentAdmin.data?currentAdmin.data.username:"",
        "email":currentAdmin && currentAdmin.data?currentAdmin.data.email:"",
        "firstname":currentAdmin && currentAdmin.data?currentAdmin.data.firstname:"",
        "lastname":currentAdmin && currentAdmin.data?currentAdmin.data.lastname:"",
        "contact":currentAdmin && currentAdmin.data?currentAdmin.data.contact:"",
      })
    } 

  }, []);
  const onChangeHandler =(e,type)=>{
    const { name, value } = e.target;
    if(type ==="profile"){     
      setProfileData(profileData => ({ ...profileData, [name]: value }));
    }
    if(type==="password"){  
      setChangePasswordData(changePasswordData => ({ ...changePasswordData, [name]: value }));
    }
  }
  
  
  const onEditHandler= async()=>{
    setsubmitted(true);
    setSuccessful(false);
    if (profileData.firstname && profileData.lastname && profileData.email && profileData.contact  ) {
      
      dispatch(adminActions.update(profileData)).then(() => {
        setSuccessful(true);
      })
      .catch(() => {
        setSuccessful(true);      
      });
   }
  }
 
  async function handleSubmitPassword(e) {
    e.preventDefault();
    setSuccessful(false);
    setPasswordsubmitted(true);
    if (changePasswordData.password && changePasswordData.confirm_password && (changePasswordData.password === changePasswordData.confirm_password) ) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/change_password`,changePasswordData);
        
        dispatch(alertActions.success(response.data.msg)); 
        setSuccessful(true);
        setPasswordsubmitted(false);
        setChangePasswordData({
          id: currentAdmin&&currentAdmin.data?currentAdmin.data.id:"",
          password: "",
          confirm_password: "",
        })
      } catch (error) {
        console.error(error);
        dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!")); 
        setSuccessful(true);
      }
    }
  }
  if (!currentAdmin) {
    return <Navigate to="/admin/login" />;
  }
  return (
    <>
    {successful?
      <ToastMessageComp  message={alert.message} type={alert.type} />
    :""}
     
          <div className='w-full h-full bg-white p-8'>
            <Typography variant="h4" component="h4" fontWeight={500}  color={'#1CBE93'}>Profile</Typography>
            <div>
                  <div className='pt-2 pb-2' style={{ borderBottom: '0.5px solid #DEE3E2' }}>
                    <h2 className='text-lg sm:text-[22px] font-bold text-primary'>mettre à jour les informations</h2>
                  </div>
                  <div className='py-3 sm:py-2 '>
                    <div className='grid grid-cols-12 items-center  sm:mt-6 gap-y-8 gap-x-5'>
                    <div className='col-span-12 sm:col-span-2 md:col-span-6'>
                      <TextField label="username"  variant="outlined" className='w-full' name='username'  value={profileData.username}  error={!profileData.username && submitted?true:false} disabled={true}  onChange={(e)=>{onChangeHandler(e,"profile")}} />
                    </div>  
                    <div className='col-span-12 sm:col-span-6 md:col-span-6'>
                      <TextField label="Adresse mail" variant="outlined" className='w-full' name='email' value={profileData.email}  error={!profileData.email && submitted?true:false}  onChange={(e)=>{onChangeHandler(e,"profile")}} />
                    </div>
                    <div className='col-span-12 sm:col-span-2 md:col-span-6'>
                      <TextField label="Prénom" variant="outlined" className='w-full' name='firstname' value={profileData.firstname}  error={!profileData.firstname && submitted?true:false} onChange={(e)=>{onChangeHandler(e,"profile")}} />
                    </div>
                    <div className='col-span-12 sm:col-span-2 md:col-span-6'>
                      <TextField label="Nom" variant="outlined" className='w-full' name='lastname' value={profileData.lastname} error={!profileData.lastname && submitted?true:false}  onChange={(e)=>{onChangeHandler(e,"profile")}} />
                    </div> 

                    <div className='col-span-12 sm:col-span-12 md:col-span-12'>
                      <TextField label="Contact" variant="outlined" className='w-full' name='contact' value={profileData.contact}  error={!profileData.contact && submitted?true:false}  onChange={(e)=>{onChangeHandler(e,"profile")}} />
                    </div> 
                    <button className='w-48 h-12 rounded-md text-secondary font-extrabold text-lg 3xl:text-xl border-2 border-secondary  transition-all duration-300 hover:border-secondary hover:text-white hover:btnShadow  hover:bg-secondary' onClick={onEditHandler} >Modifier</button>

                  </div>

                  <div className='py-6 mt-8' style={{ borderBottom: '0.5px solid #DEE3E2' }}>
                    <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Mettre à jour le mot de passe</h2>
                  </div>
                  <div className='grid grid-cols-12 items-center  sm:mt-6 gap-y-8 gap-x-5'>
                    <div className='col-span-12 sm:col-span-6 md:col-span-6'>
                      <TextField label="Nouveau mot de passe" type={"password"} variant="outlined" className='w-full'  name='password'  value={changePasswordData.password}  error={!changePasswordData.password && passwordsubmitted?true:false}  onChange={(e)=>{onChangeHandler(e,"password")}} />
                    </div>
                    <div className='col-span-12 sm:col-span-6 md:col-span-6'>
                      <TextField label="Confirmer le nouveau mot de passe" type={"password"} variant="outlined" className='w-full'  name='confirm_password'  value={changePasswordData.confirm_password}   error={(passwordsubmitted && !changePasswordData.confirm_password) || (changePasswordData.password !== changePasswordData.confirm_password) ? true : false}   onChange={(e)=>{onChangeHandler(e,"password")}} />
                    </div>
                    <button className='w-48 h-12 rounded-md text-secondary font-extrabold text-lg 3xl:text-xl border-2 border-secondary  transition-all duration-300 hover:border-secondary hover:text-white hover:btnShadow  hover:bg-secondary' onClick={handleSubmitPassword} >Modifier</button>

                  </div>
                </div>
              </div>
          </div>
         
  </>
  )
}
