/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import axios from "axios";
import SideBar from "./SideBar";
import Footer from "../component/Footer";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../_actions";
import { alertActions } from "../_actions";
import ToastMessageComp from "../component/ToastMessageComp";
import { history } from "../_helpers";
import ScreenWrapper from "./ScreenWrapper";

export default function Profile() {
  const currentUser = useSelector((state) => state.authentication.user);
  let navigate = useNavigate();
  const [changePasswordData, setChangePasswordData] = useState({
    id: currentUser && currentUser.data ? currentUser.data.id : "",
    password: "",
    confirm_password: "",
  });
  const [user, setUser] = useState({
    id: currentUser && currentUser.data ? currentUser.data.id : "",
    siren: currentUser && currentUser.data ? currentUser.data.siren : "",
    raisosociale:
      currentUser && currentUser.data ? currentUser.data.raisosociale : "",
    civil: currentUser && currentUser.data ? currentUser.data.civil : "",
    firstname:
      currentUser && currentUser.data ? currentUser.data.firstname : "",
    lastname: currentUser && currentUser.data ? currentUser.data.lastname : "",
    telephone:
      currentUser && currentUser.data ? currentUser.data.telephone : "",
    email: currentUser && currentUser.data ? currentUser.data.email : "",
    adresse: currentUser && currentUser.data ? currentUser.data.address : "",
    postalcode:
      currentUser && currentUser.data ? currentUser.data.postalcode : "",
    ville: currentUser && currentUser.data ? currentUser.data.ville : "",
    ville_id: currentUser && currentUser.data ? currentUser.data.ville_id : "",
    villeArr:
      currentUser && currentUser.data && currentUser.data.villeArr
        ? JSON.parse(currentUser.data.villeArr)
        : [],
    profile_image_old:
      currentUser && currentUser.data ? currentUser.data.profile_image : "",
    profile_image: "",
    // profile_image_arr:"",
    edit_type: "normal",
  });
  const [submitted, setSubmitted] = useState(false);
  const [submittedPass, setSubmittedPass] = useState(false);
  const [successful, setSuccessful] = useState(false);
  // const registering = useSelector(state => state.registration.registering);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }, []);

  useEffect(() => {
    const getData = setTimeout(async () => {
      setUser((user) => ({
        ...user,
        ["ville"]: "",
        ["ville_id"]: "",
        ["villeArr"]: [],
      }));
      if (user && user.postalcode && user.postalcode.length > 0) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/checkzipcode/${user.postalcode}`
        );

        if (response.data && response.data.data) {
          setUser((user) => ({
            ...user,
            ["ville"]: response.data.data.city,
            ["ville_id"]: response.data.data.id,
            ["villeArr"]: response.data.data.cityArr,
          }));
        }
      }
    }, 1000);
    return () => clearTimeout(getData);
  }, [user.postalcode]);

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  function handleChange(e) {
    const { name, value } = e.target;

    if (name === "profile_image") {
      let nameImg = `profile_image_arr`;
      let filesArr = e.target.files[0];
      setUser((user) => ({ ...user, [name]: e.target.files[0] }));
      // setUser(user => ({ ...user, [nameImg]: e.target.files[0] }));
    } else {
      setUser((user) => ({ ...user, [name]: value }));
    }

    if (name == "ville_id") {
      let filterCity = user.villeArr.filter((cityVal) => cityVal.id === value);
      if (filterCity.length > 0) {
        setUser((user) => ({
          ...user,
          ["ville"]: filterCity[0].city,
          ["ville_id"]: filterCity[0].id,
        }));
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSuccessful(false);
    setSubmitted(true);

    if (
      user.firstname &&
      user.lastname &&
      user.adresse &&
      user.postalcode &&
      user.ville
    ) {
      dispatch(userActions.update(user))
        .then(() => {
          setSuccessful(true);
          setTimeout(() => {
            // navigate("/login");
          }, 2000);
        })
        .catch(() => {
          setSuccessful(true);
        });
    }
  }
  function handleChangePassword(e) {
    const { name, value } = e.target;
    setChangePasswordData((changePasswordData) => ({
      ...changePasswordData,
      [name]: value,
    }));
  }
  async function handleSubmitPassword(e) {
    e.preventDefault();
    setSuccessful(false);
    setSubmittedPass(true);
    if (
      changePasswordData.password &&
      changePasswordData.confirm_password &&
      changePasswordData.password === changePasswordData.confirm_password
    ) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/changepassword`,
          changePasswordData
        );

        dispatch(alertActions.success(response.data.msg));
        setSuccessful(true);
        setSubmittedPass(false);
        setChangePasswordData({
          id: currentUser && currentUser.data ? currentUser.data.id : "",
          password: "",
          confirm_password: "",
        });
      } catch (error) {
        console.error(error);
        dispatch(
          alertActions.error("Une erreur s'est produite. Veuillez réessayer!")
        );
        setSuccessful(true);
      }
    }
  }

  return (
    <>
      {successful ? (
        <ToastMessageComp message={alert.message} type={alert.type} />
      ) : (
        ""
      )}
      <ScreenWrapper>
        <div className="xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px]">
          <div className="mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 sm:justify-between">
            <div>
       
              <h1 className="text-lg sm:text-2xl xl:text-2xl 2xl:text-[25px] font-black text-white sm:mt-0 mt-4">
                Paramètres de profil
              </h1>
            </div>
          </div>
          <div>
            <div
              className="py-6 px-3 sm:px-6"
              style={{ borderBottom: "0.5px solid #DEE3E2" }}
            >
              <h2 className="text-lg sm:text-[22px] font-bold text-primary">
                Informations de base
              </h2>
            </div>
            <div className="py-3 sm:py-2 px-3 sm:px-6">
              <form
                onSubmit={handleSubmit}
                method="post"
                encType="multipart/form-data"
              >
                <div className="grid grid-cols-12 items-center  sm:mt-6 gap-y-8 gap-x-5">
                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    <TextField
                      label="Siren"
                      variant="outlined"
                      className="w-full"
                      name="siren"
                      value={user.siren}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    <TextField
                      label="Dénomination"
                      variant="outlined"
                      className="w-full"
                      name="raisosociale"
                      value={user.raisosociale}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-2 md:col-span-2">
                    <FormControl className="w-full">
                      <InputLabel id="Civilite">Civilité</InputLabel>
                      <Select
                        labelId="Civilite"
                        label="Civilité"
                        name="civil"
                        value={user.civil}
                        onChange={handleChange}
                      >
                        <MenuItem value={"M"}>M</MenuItem>
                        <MenuItem value={"MME"}>MME</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-span-12 sm:col-span-2 md:col-span-5">
                    <TextField
                      label="Nom"
                      variant="outlined"
                      className="w-full"
                      error={submitted && !user.firstname ? true : false}
                      value={user.firstname}
                      name="firstname"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-2 md:col-span-5">
                    <TextField
                      label="Prénom"
                      variant="outlined"
                      className="w-full"
                      error={submitted && !user.lastname ? true : false}
                      name="lastname"
                      value={user.lastname}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6 md:col-span-6 ">
                    <TextField
                      label="Adresse mail"
                      disabled
                      variant="outlined"
                      className="w-full "
                      name="email"
                      value={user.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    <TextField
                      label="Téléphone"
                      variant="outlined"
                      className="w-full"
                      name="telephone"
                      value={user.telephone}
                      onChange={handleChange}
                    />
                    {/* error={submitted && !user.telephone ? true : false} */}
                  </div>

                  <div className="col-span-12 sm:col-span-12 md:col-span-12">
                    <TextField
                      label="Adresse"
                      variant="outlined"
                      className="w-full"
                      error={submitted && !user.adresse ? true : false}
                      name="adresse"
                      value={user.adresse}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    <TextField
                      label="Code postal"
                      variant="outlined"
                      className="w-full"
                      error={submitted && !user.postalcode ? true : false}
                      name="postalcode"
                      value={user.postalcode}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    {/*  <TextField label="Ville" variant="outlined" className='w-full' error={submitted && !user.ville ? true : false} name="ville" value={user.ville} onChange={handleChange}  /> */}
                    <Select
                      className="w-full"
                      label="Ville"
                      variant="outlined"
                      id="ville_id"
                      error={submitted && !user.ville ? true : false}
                      name="ville_id"
                      value={user.ville_id}
                      onChange={handleChange}
                    >
                      {user.villeArr.map((cityVal, cityIndex) => {
                        return (
                          <MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="col-span-12 sm:col-span-12 md:col-span-12">
                    <div
                      className="py-4 "
                      style={{ borderBottom: "0.5px solid #DEE3E2" }}
                    >
                      <h2 className="text-lg sm:text-[22px] font-bold text-primary">
                        Importez votre image
                      </h2>
                    </div>
                    <div className="flex flex-col sm:flex-row sm:items-center sm:gap-8 mt-8">
                      <button
                        variant="contained"
                        component="label"
                        className="cursor-pointer relative border-dashed border-[#797979] border-2 rounded-md w-full sm:w-[361px] h-[120px] px-3 sm:h-[84px] flex items-center text-center justify-center"
                      >
                        <p className="text-sm font-light text-black max-w-[290px] m-auto">
                          Glisser-déposer le image dans cette zone ou{" "}
                          <span className="font-bold text-primary">
                            cliquez ici pour sélectionner un image
                          </span>{" "}
                        </p>
                        <input
                          type="file"
                          className="absolute w-full h-full opacity-0 cursor-pointer"
                          name="profile_image"
                          onChange={handleChange}
                        />
                      </button>
                      <div>
                        <p className="text-[15px] text-primary font-light">
                          Format :{" "}
                          <span className="font-medium">jpeg, png </span>
                        </p>
                        <p className="text-[15px] text-primary font-light">
                          Poids :{" "}
                          <span className="font-medium">5 Mo max. </span>
                        </p>
                        <p className="text-[15px] text-primary font-light">
                          Dimensions :
                          <span className="font-medium">150*150px min.</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <button className="w-48 h-12 rounded-md text-thColor font-extrabold text-lg 3xl:text-xl border-2 border-thColor  transition-all duration-300 hover:border-secondary hover:text-white hover:btnShadow  hover:bg-secondary">
                    Modifier
                  </button>
                </div>
              </form>
              <div
                className="py-6 mt-8"
                style={{ borderBottom: "0.5px solid #DEE3E2" }}
              >
                <h2 className="text-lg sm:text-[22px] font-bold text-primary">
                  Changer votre mot de passe
                </h2>
              </div>
              <form onSubmit={handleSubmitPassword} method="post">
                <div className="grid grid-cols-12 items-center  sm:mt-6 gap-y-8 gap-x-5">
                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    <TextField
                      label="Nouveau mot de passe"
                      variant="outlined"
                      type={"password"}
                      name="password"
                      className="w-full"
                      error={
                        submittedPass && !changePasswordData.password
                          ? true
                          : false
                      }
                      value={changePasswordData.password}
                      onChange={handleChangePassword}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    <TextField
                      label="Confirmer le nouveau mot de passe"
                      type={"password"}
                      name="confirm_password"
                      variant="outlined"
                      className="w-full"
                      error={
                        (submittedPass &&
                          !changePasswordData.confirm_password) ||
                        changePasswordData.password !==
                          changePasswordData.confirm_password
                          ? true
                          : false
                      }
                      value={changePasswordData.confirm_password}
                      onChange={handleChangePassword}
                    />
                  </div>
                  <button className="w-48 h-12 rounded-md text-thColor font-extrabold text-lg 3xl:text-xl border-2 border-thColor  transition-all duration-300 hover:border-secondary hover:text-white hover:btnShadow  hover:bg-secondary">
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ScreenWrapper>
      <Footer />
    </>
  );
}
