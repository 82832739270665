import React from 'react'
import { Link } from 'react-router-dom'

export default function NeedHelp() {
  return (
    <div className=' w-full mt-14 mb-16'>
   <h2 className='text-lg sm:text-2xl 3xl:text-[25px] text-gray text-center py-12 font-extrabold'>Besoin d’aide dans vos démarches ?</h2>
    <div className='flex flex-col sm:flex-row gap-6 sm:ga   justify-center items-center mx-4'>

      <div className='bg-white w-full max-w-[388px] h-full max-h-[261px] min-h-[261px] rounded-[41px] flex justify-center items-center  p-6 md:p-12 ' style={{ boxShadow: '0px 4px 14px 2px rgba(145, 143, 143, 0.25)' }}>

        <div className='text-center w-full h-full flex flex-col items-center justify-center'>
          <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.5 0C27.3808 0 24.2308 3.15 24.2308 7.26923V21.8077C24.2308 25.9269 27.3808 29.0769 31.5 29.0769H46.0385L55.7308 38.7692V29.0769C59.85 29.0769 63 25.9269 63 21.8077V7.26923C63 3.15 59.85 0 55.7308 0H31.5ZM41.6478 7.26923H45.8107L49.9008 21.8077H46.2662L45.28 18.1731H41.6454L40.7392 21.8077H37.5577L41.6478 7.26923ZM43.6154 9.69231C43.3731 10.6615 43.1017 11.844 42.857 12.5709L42.1785 15.75H45.0547L44.3714 12.5685C43.8892 11.844 43.6154 10.6615 43.6154 9.69231ZM7.26923 24.2308C3.15 24.2308 0 27.3808 0 31.5V46.0385C0 50.1577 3.15 53.3077 7.26923 53.3077V63L16.9615 53.3077H31.5C35.6192 53.3077 38.7692 50.1577 38.7692 46.0385V31.5H31.5C26.8962 31.5 23.247 28.35 22.0355 24.2308H7.26923ZM18.4008 31.2722C22.5201 31.2722 24.4585 34.6645 24.4585 38.5415C24.4585 41.9338 23.293 44.0855 21.3546 45.0547C22.3238 45.5393 23.4723 45.7962 24.6839 46.0385L23.7777 48.4615C22.0815 47.9769 20.3223 47.2209 18.6262 46.4916C18.3839 46.2493 17.9598 46.2662 17.7175 46.2662C14.8098 46.0239 12.1154 43.6154 12.1154 38.7692C12.1154 34.65 14.5239 31.2722 18.4008 31.2722ZM18.4008 33.9231C16.4624 33.9231 15.5222 36.1038 15.5222 38.7692C15.5222 41.6769 16.4624 43.6154 18.4008 43.6154C20.3393 43.6154 21.3522 41.4346 21.3522 38.7692C21.3522 36.1038 20.3393 33.9231 18.4008 33.9231Z" fill="#1CBE93" />
          </svg>


          <h4 className='font-semibold text-lg  text-gray text-center mt-6'>Consultez notre FAQ</h4>
          <p className='font-light text-sm text-gray  3xl:mt-4 sm:max-w-[388px]'>Retrouvez les réponses aux questions les plus fréquentes posées par nos clients.</p>
          <Link to="/faq" className='btnShadow text-white flex items-center justify-center rounded-[33px] text-sm font-bold  h-[33px] w-[153px] transition-all duration-300 mt-4 bg-primary hover:bg-secondary'>Consulter la FAQ</Link>

        </div>
      </div>

      <div className='bg-white w-full max-w-[388px] h-full max-h-[261px] min-h-[261px] rounded-[41px] flex justify-center items-center  p-6 md:p-12 ' style={{ boxShadow: '0px 4px 14px 2px rgba(145, 143, 143, 0.25)' }}>

        <div className='text-center w-full h-full flex flex-col items-center justify-center'>
          <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M49.1666 11.8027V31.4792C49.1667 33.4854 48.3992 35.4156 47.0214 36.8738C45.6436 38.332 43.76 39.2078 41.7571 39.3213L41.3124 39.3334H8.68742C6.68125 39.3335 4.75108 38.566 3.29286 37.1882C1.83464 35.8104 0.958915 33.9269 0.845336 31.9239L0.833252 31.4792V11.8027L24.1589 24.0214C24.4184 24.1573 24.707 24.2283 24.9999 24.2283C25.2929 24.2283 25.5814 24.1573 25.8409 24.0214L49.1666 11.8027ZM8.68742 0.666748H41.3124C43.2592 0.666514 45.1368 1.38932 46.5809 2.69498C48.0249 4.00063 48.9326 5.79608 49.1279 7.73308L24.9999 20.3722L0.871919 7.73308C1.05927 5.873 1.90396 4.14075 3.25415 2.8477C4.60434 1.55465 6.37148 0.785617 8.23792 0.678832L8.68742 0.666748H41.3124H8.68742Z" fill="#1CBE93" />
          </svg>


          <h4 className='font-semibold text-lg  text-gray text-center mt-6'>Envoyez-nous un Email</h4>
          <p className='font-light text-sm text-gray  3xl:mt-4 sm:max-w-[233px]'>Nos services s’efforceront de vous répondre au plus vite. </p>
          <Link to="mailto:contact@busyplace.fr" className='btnShadow text-white flex items-center justify-center rounded-[33px] text-sm font-bold  h-[33px] w-[153px] transition-all duration-300 mt-4 bg-primary hover:bg-secondary'>Contactez-nous</Link>

        </div>
      </div>


      <div className='bg-white w-full max-w-[388px] h-full max-h-[261px] min-h-[261px]   rounded-[41px] flex justify-center items-center  p-6 md:p-12 ' style={{ boxShadow: '0px 4px 14px 2px rgba(145, 143, 143, 0.25)' }}>

        <div className='text-center w-full h-full flex flex-col items-center justify-center'>
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 0C10.752 0 0 10.752 0 24C0 37.248 10.752 48 24 48C37.248 48 48 37.248 48 24C48 10.752 37.248 0 24 0ZM24 9.6C28.632 9.6 32.4 13.368 32.4 18C32.4 22.632 28.632 26.4 24 26.4C19.368 26.4 15.6 22.632 15.6 18C15.6 13.368 19.368 9.6 24 9.6ZM24 43.2C19.128 43.2 13.368 41.232 9.264 36.288C13.4679 32.9897 18.6567 31.1971 24 31.1971C29.3433 31.1971 34.5322 32.9897 38.736 36.288C34.632 41.232 28.872 43.2 24 43.2Z" fill="#1CBE93" />
          </svg>


          <h4 className='font-semibold text-lg  text-gray text-center mt-6'>Contactez un conseiller</h4>
          <p className='font-light text-sm text-gray  3xl:mt-4 sm:max-w-[233px]'>Du lundi au vendredi de 10h à 12h
            et de 14h à 18h</p>
            <Link to="tel:01 53 65 16 66" className='btnShadow text-white rounded-[33px] text-sm font-bold flex items-center justify-center h-[33px] w-[153px] transition-all duration-300 mt-4 bg-primary hover:bg-secondary'>Appelez-nous</Link>

        </div>

      </div>
    </div>
  </div>
  )
}
