/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import "./Style.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Footer from "../component/Footer";
import { MdModeEditOutline } from "react-icons/md";
import { BsCreditCard2Back } from "react-icons/bs";
import { Link, Navigate } from "react-router-dom";
import SearchHead from "./SearchHead";
import { useSelector, useDispatch } from "react-redux";
import { orderActions } from "../_actions";
import { alertActions } from "../_actions";
import { changeDateFormatFrench, convertNumberFrench } from "../_helpers";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import ScreenWrapper from "./ScreenWrapper";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export default function AnnoncesEnAttente() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [TabHead, setTabHead] = useState([
    "DENOMINATION",
    "FORMULAIRE",
    "JOURNAL",
    "MONTANT TOTAL",
    "DATE DE CRÉATION",
    "",
  ]);
  const [TabData, setTabData] = useState([]);

  useEffect(() => {
    getAllDevise();
  }, []);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TabData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllDevise = () => {
    dispatch(orderActions.getAll(currentUser.data.id, "order"))
      .then((data) => {
        setTabData(data);
      })
      .catch(() => { });
  };
  const currentUser = useSelector((state) => state.authentication.user);
  const orders = useSelector((state) => state.orders.devis);

  if (!currentUser) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <ScreenWrapper>
        <div className="xl:max-w-[1126px] w-full h-full bg-white rounded-[20px]">
          <div className="mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 sm:justify-between">
            <div>
              <h1 className="text-lg sm:text-2xl xl:text-2xl 2xl:text-[25px] font-black text-white sm:mt-0 mt-4">
                Annonces en attente
              </h1>
            </div>
            <div className="w-full sm:w-auto flex justify-end">
              <SearchHead />
            </div>
          </div>

          <div className="mt-8 hidden lg:block">
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {TabHead.map((thItem, i) => {
                      return (
                        <TableCell
                          key={i}
                          className="text-base font-normal text-black-light whitespace-nowrap"
                        >
                          {thItem}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? TabData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    : TabData
                  ).map((tdItem, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell
                          className="text-base font-normal text-primary whitespace-nowrap"
                          align="left"
                        >
                          {tdItem.username}
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary whitespace-nowrap"
                          align="left"
                        >
                          {tdItem.form_type}
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary whitespace-nowrap"
                          align="left"
                        >
                          {tdItem.journal_detail}
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary whitespace-nowrap"
                          align="left"
                        >
                          {convertNumberFrench(tdItem.total)} €
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary whitespace-nowrap"
                          align="left"
                        >
                          {changeDateFormatFrench(tdItem.creation_date)}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="flex items-center justify-center gap-4"
                        >
                          <Link
                            to={`/nos-formulaires/${tdItem.title}/${tdItem.form_type}/${tdItem.id}`}
                            className="text-base font-normal text-primary border border-primary rounded-full flex items-center justify-center w-9 h-9 hover:bg-secondary hover:border-secondary hover:text-white"
                          >
                            <MdModeEditOutline className="w-5 h-5" />
                          </Link>
                          {/* <Link className='text-base font-normal text-primary border border-primary rounded-full flex items-center justify-center w-9 h-9  hover:bg-secondary hover:border-secondary hover:text-white'>
                                    <BsCreditCard2Back className='w-5 h-5' />
                                  </Link> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      // colSpan={3}
                      count={TabData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>

          <div className="mt-8 block lg:hidden">
            <div className="bg-white rounded-[20px] shadow-md mx-2 p-3 ">
              {(rowsPerPage > 0
                ? TabData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : TabData
              ).map((tdItem, i) => {
                let ann_details_url = `/userdashboard/annonces-publiees-details/${tdItem.id}`;
                return (
                  <div className="flex justify-between py-3">
                    <div className="flex justify-between flex-col gap-2">
                      {TabHead.map((thItem, i) => {
                        return (
                          <Box
                            key={i}
                            className="text-xs font-normal text-black-light border-0 whitespace-nowrap"
                          >
                            {thItem}
                          </Box>
                        );
                      })}
                    </div>
                    <div className="flex justify-between flex-col gap-2">
                      <div> {tdItem.username}</div>
                      <div>{tdItem.form_type}</div>
                      <div> {tdItem.journal_detail}</div>
                      <div>  {convertNumberFrench(tdItem.total)} €</div>
                      <div>{changeDateFormatFrench(tdItem.creation_date)}</div>
                      <div> <Link
                        to={`/nos-formulaires/${tdItem.title}/${tdItem.form_type}/${tdItem.id}`}
                        className="text-base font-normal text-primary border border-primary rounded-full flex items-center justify-center w-9 h-9 hover:bg-secondary hover:border-secondary hover:text-white"
                      >
                        <MdModeEditOutline className="w-5 h-5" />
                      </Link></div>

                    </div>
                  </div>
                )
              })}

            </div>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: "All", value: -1 },
                  ]}
                  // colSpan={3}
                  count={TabData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: false,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </div>

        </div>
      </ScreenWrapper>
      <Footer />
    </>
  );
}
