import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  MenuItem,
  Grid,
  Pagination,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import Breadcrumb from './Directory/Breadcrumb';
import Footer from '../component/Footer';
import axios from 'axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { frFR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import 'dayjs/locale/fr.js';
import moment from 'moment';
import { Helmet } from 'react-helmet';
const TabHead = ['Publiée le', 'Type d annonce', 'Dénomination', 'Département', 'journal'];

const RechercheDesAnnocesLegales = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [publicationDate, setPublicationDate] = useState('');
  const [announcementType, setAnnouncementType] = useState('');
  const [department, setDepartment] = useState('');
  const [keywords, setKeywords] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [announcements, setAnnouncements] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [departmentData, setDepartmentData] = useState([]);
  const [announceDropdownData, setAnnounceDropdownData] = useState([
    { value:"eurl",name:"SARL Unipersonnelle (EURL)"},
    { value:"sarl",name:"Société à Responsabilité Limitée (SARL)"},
    { value:"sasu",name:"Société par Actions Simplifiée Unipersonnelle (SASU)"},
    { value:"sas",name:"Société par Actions Simplifiée (SAS)"},
    { value:"sa",name:"Société Anonyme (SA)"},
    { value:"snc",name:"Société en Nom Collectif (SNC)"},
    { value:"sc",name:"Société Civile"},
    { value:"sci",name:"Société Civile Immobilière (SCI)"},
    { value:"sccv",name:"SC de Construction Vente (SCCV ou SCICV)"},
    { value:"scm",name:"Société Civile De Moyens (SCM)"},
    { value:"scpi",name:"Société Civile De Placement Immobilier (SCPI)"},
    { value:"scp",name:"Société Civile Professionnelle (SCP)"},
    { value:"selarl",name:`Société d'Exercice Libéral à Responsabilité Limitée (SELARL)`},
    { value:"selca",name:`Société d'exercice libéral en commandite par Actions (SELCA)`},
    { value:"seleurl",name:`Société d'Exercice Libéral ayant un associé Unique (SELEURL)`},
    { value:"selafa",name:`Société d'Exercice Libéral sous Forme Anonyme (SELAFA)`},
    { value:"selas",name:`Société d'Exercice Libéral par Actions Simplifiée (SELAS)`},
    { value:"selasu",name:`Société d'Exercice Libéral par Actions Simplifiée Unipersonnelle (SELASU)`},
    { value:"selca",name:`Société d'exercice libéral en commandite par Actions (SELCA)`},
    { value:"modifications-multiples",name:`Modifications multiples`},
    { value:"changement-gerant",name:`Changement Gérant/Président/ Nomination`},
    { value:"transfert-siege-meme-departement",name:`Transfert de siége social dans le département`},
    { value:"transfert-siege-hors-departement-depart",name:`Transfert de siége social hors département (départ)`},
    { value:"transfert-siege-hors-departement-arrivee",name:`Transfert de siége social hors département (arrivée)`},
    { value:"changement-denomination",name:`Changement de dénomination`},
    { value:"changement-objet-social",name:`Modification d'objet social`},
    { value:"continuation-activite",name:`Poursuite d'activité malgré pertes`},
    { value:"modification-capital-social",name:`Avis de modification de capital`},
    { value:"transformation-sarl-en-sas",name:`Transformation d’une SARL en SAS`},
    { value:"transformation-sas-en-sarl",name:`Transformation d’une SAS en SARL`},
    { value:"cession-parts-sociales",name:`Cession de parts/actions sociales`},
    { value:"transformation-sa-en-sas",name:`Transformation d’une SA en SAS`},
    { value:"prorogation-duree",name:`Prorogation de durée`},
    { value:"dissolution-anticipee",name:`Avis de dissolution anticipée`},
    { value:"cloture-liquidation",name:`Avis de clôture de liquidation`},
    { value:"dissolution-sans-liquidation",name:`Avis de dissolution sans liquidation`},
    { value:"apport-fond-commerce",name:`Apport de Fonds de Commerce`},
    { value:"fond-commerce/cession-droit-bail",name:`Cession de Droit au Bail`},
    { value:"fond-commerce/fin-location-gerance",name:`Fin de location gérance`},
    { value:"fond-commerce/location-gerance",name:`Location gérance`},
    { value:"cession-fond-commerce",name:`Vente de Fonds de Commerce`},
    { value:"additif",name:`Additif`},
    { value:"rectificatif",name:`Annonce rectificative`},
    ]);

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setPublicationDate(date);
  };
console.log("publicationDate",publicationDate)

  const itemsPerPage = 5;

  const handleSubmitData = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const requestData = {
      form_type: announcementType,
      department: department,
      searchtext: searchQuery,
      date_de_publication: publicationDate?dayjs(publicationDate).format('YYYY-MM-DD'):"",
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/announce/recherche-des-annoces-legales`, requestData);
      setAnnouncements(response.data.annonces);
      setDepartmentData(response.data.department);
      setPageCount(Math.ceil(response.data.annonces.length / itemsPerPage));

      console.log('API response:', response.data);
    } catch (error) {
      console.error('Error while making API call:', error);
    }
  };

  useEffect(() => {
    if (!announcements.length) {
      handleSubmitData(); // Call the function only if announcements is empty (component is mounted)
    }
  }, [announcements]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const offset = (currentPage - 1) * itemsPerPage;
  const currentPageData = announcements.slice(offset, offset + itemsPerPage);



  const [breadcrumbsData] = useState({
    "breadCrumbs": [
      // {"path":"/recherche-des-annoces-legales"}
    ],
    "main": { "pagename": " Recherche des annonces légales", "path": "/recherche-des-annoces-legales" }
  });

  return (
    <>
     <Helmet>
        <title>Consulter les annonces légales publiées  | Busyplace</title>
        <meta name="description" content="Consulter les dernières publications légales et judiciaires publiées de mises à jour instantanées pour rester informé en temps réel." />
        {/* <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/recherche-des-annoces-legales`} /> */}
      </Helmet>
    <div className='bg-mainBg'>
        <div className='md:max-w-[1477px] relative w-full h-full m-auto py-7 px-4 sm:px-14 myForm'>
        <Breadcrumb page=" Recherche des annonces légales" breadcrumbsData={breadcrumbsData} />
        <h1 className=' text-2xl xl:text-3xl mt-2'> Consulter les dernières annonces légales publiées</h1>
          <div className='relative  bg-mainBg my-4 sm:my-12 '>
            <div className='grid grid-cols-12 gap-5  '>
                <div className=' col-span-12 2lg:col-span-3  '>
                <form onSubmit={(e) => handleSubmitData(e)} className='bg-white p-4 rounded-lg'>
                    <div className="flex flex-col mb-4">

                        <label htmlFor='publicationDate' className='text-sm'>
                        Date de Publication
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <DatePicker
                            selected={publicationDate}
                            onChange={(date) => setPublicationDate(date.$d)}
                            dateFormat="dd/MM/yyyy" // Customize date format as needed
                            customInput={<TextField fullWidth variant='outlined' margin='normal' size='small' />}
                        />
                        </LocalizationProvider>
                    </div>
                    <div className="flex flex-col mb-4">
                        <label htmlFor="department" className="text-sm ">Type d'annonce</label>
                        <TextField
                        select
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={announcementType}
                        onChange={(e) => setAnnouncementType(e.target.value)}
                        placeholder='Votre type de formulaire'
                        >
                        <MenuItem value="">Votre type de formulaire</MenuItem>
                        {
                            announceDropdownData.map((value,key)=>{
                                return <MenuItem value={value.value}>{value.name}</MenuItem>
                            })
                        }
                        </TextField>
                    </div>
                    <div className="flex flex-col mb-4">
                        <label htmlFor="department" className="text-sm ">Département</label>
                        <TextField
                        select
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}
                        placeholder='Choisir un département'
                        >
                        <MenuItem value="">Choisir un département</MenuItem>
                        {
                            departmentData.map((value,key)=>{
                                return <MenuItem value={value.id}>{value.department_num} {value.department_name}</MenuItem>
                            })
                        }
                        </TextField>
                    </div>
                    <label htmlFor="department" className="text-sm ">Mots clés</label>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        size="small"
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                        placeholder='Ex: denomination'
                    />
                    {/* ... (other form inputs) */}
                    <Button type='submit' variant='contained' className='p-2 mt-3' color='success' size='small'>
                        Rechercher
                    </Button>
                    </form>
                </div>
                <div className='mb-8 col-span-12 2lg:col-span-9'>
                   
                    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                            <TableHead>
                            <TableRow>
                                {TabHead.map((thItem, i) => (
                                <TableCell key={i} className='text-base font-normal text-black-light whitespace-nowrap'>
                                    {thItem}
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {currentPageData.map((row, index) => (
                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell className='text-sm font-small text-primary whitespace-nowrap'>{moment(row.date_parution).format("DD-MM-Y")}</TableCell>
                                <TableCell className='text-sm font-small text-primary whitespace-nowrap' align='left'>
                                    {row.form_type_text}
                                </TableCell>
                                <TableCell className='text-sm font-small whitespace-nowrap' align='left'>
                                    <NavLink
                                    to={`/recherche-des-annoces-legales/${row.id}`}
                                    className='text-base font-medium text-[#009640] hover:text-[#007bff] hover:underline'
                                    >
                                    {row.votre_annonce_parse.denomination?row.votre_annonce_parse.denomination:row.votre_annonce_parse.denomination_societe?row.votre_annonce_parse.denomination_societe:row.socitename}

                                    </NavLink>
                                </TableCell>
                                <TableCell className='text-sm font-small text-primary whitespace-nowrap' align='left'>
                                    {row.department.length>0?row.department[0].department_num+" - "+row.department[0].department_name:""}
                                </TableCell>
                                <TableCell className='text-sm font-small text-primary whitespace-nowrap' align='left'>
                                    {row.journalempowered.length>0?row.journalempowered[0].journal_empowered_name:""}
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* Pagination UI */}
                    <div className="pagination-container pt-5 pb-5">
                    <Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        shape="rounded"
                    />
                    </div>
                </div>
              
            </div>
          </div>
        </div>
        <Footer />
      </div >
      </>
  );
};

export default RechercheDesAnnocesLegales;
