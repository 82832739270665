import React, { useState, useEffect, useRef } from "react";
// import FridLogo from "../assets/FridLogo.png";
import Compétences from "../assets/Compétences.svg";
import { FiMapPin } from "react-icons/fi";
import messages from "../assets/messages.svg";
import akarIconsCalendar from "../assets/akar-icons_calendar.svg";
import tel from "../assets/tel.svg";
import ReactPaginate from "react-paginate";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import searchIcon from "../assets/search.svg";
import Footer from "../component/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import Mapbox from "../component/mapbox/Mapbox";
import MessageProFormComp from "../component/messageProForm";
import no_image from "../assets/no_image.png";
import PageLoaderComp from "../component/pageLoaderComp";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { decodedStringValue } from "../_helpers";
import Breadcrumb from "./Directory/Breadcrumb";
import moment from "moment"
import ToastMessageComp from "../component/ToastMessageComp";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function DirectoryOfChartered() {
  const alert = useSelector(state => state.alert);
  const { city, postalCode } = useParams();
  const [successfull, setSuccessfull] = useState(false);
  const [data, setData] = useState([]);
  const [breadcrumbsData, setBreadcrumbsData] = useState({
    "breadCrumbs":[
      {"pagename":"Experts-comptables","path":"/experts-comptables"}
    ],
    "main":{"pagename":postalCode,"path":"/"}
  });
  const [loadingFullpage, setLoadingFullpage] = useState(false);
  const [paginateData, setPaginateData] = useState({
    "current": 1,
    "total_pages": 0,
    "total_results": 0,
    "size": 100
  });



  const [geoJson, setGeoJson] = useState({
    "type": "FeatureCollection",
    "features": []
  });
  const [latlng, setLatlng] = useState({
    "latitude":48.864716,
    "longitude":2.349014,
  });
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(100);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilterData, setSelectedFilterData] = useState( { "competences":[] ,"secteur_dactivite": [] , "langues":[],"accessibilites":[] }
  );
  const [filterData, setFilterData] = useState({
    "competences":[
      {value :"Accélération de la production des comptes"},
      {value :"Accompagnement à la création d'entreprise"},
      {value :"Accompagnement à la digitalisation"},
      {value :"Accompagnement au rachat d'entreprise"},
      {value :"Accompagnement au recrutement"},
      {value :"Accompagnement des associations foncières"},
      {value :"Affaires fiscales internationales"},
      {value :"Analyse économique et financière"},
      {value :"Audit comptable et financier"},
      {value :"Audit d'acquisition et cession"},
      {value :"Bilan annuel"},
      {value :"Bilans"},
      {value :"Comité d'entreprise"},
      {value :"Commissaire à la fusion"},
      {value :"Commissaire à la transformation"},
      {value :"Commissaire aux comptes"},
      {value :"Comptabilité analytique"},
      {value :"Comptabilité client-fournisseur"},
      {value :"Compte d'exploitation"},
      {value :"Compte prévisionnel"},
      {value :"Comptes de campagne"},
      {value :"Conseil"},
      {value :"Conseil aux particuliers"},
      {value :"Conseil en gestion de patrimoine"},
      {value :"Conseil en gestion d'entreprise"},
      {value :"Conseil en investissement immobilier"},
      {value :"Conseil fiscal"},
      {value :"Conseils en import-export"},
      {value :"Consultant en stratégie et RH"},
      {value :"Contrôle de gestion"},
      {value :"Contrôle fiscal des comptabilités informatisées"},
      {value :"COO externalisé"},
      {value :"Création d'entreprise"},
      {value :"Cryptomonnaie"},
      {value :"CSE"},
      {value :"Déclaration d'imppôts sur le revenu"},
      {value :"Déclarations d'impôts sur les successions"},
      {value :"Déclarations fiscales"},
      {value :"Déclarations sociales"},
      {value :"Dématérialisation"},
      {value :"Dématérialisation digitale"},
      {value :"Droit douanier"},
      {value :"Droit fiscal des associations"},
      {value :"Droit pénal fiscal"},
      {value :"Elaboration budgétaire"},
      {value :"Evaluation d'entreprise"},
      {value :"Execution testamentaire"},
      {value :"Expert judiciaire"},
      {value :"Filiale étrangère"},
      {value :"Formation à la création d'entreprise"},
      {value :"Fusion-acquisition"},
      {value :"Gestion de la paie"},
      {value :"Gestion de patrimoine"},
      {value :"Gestion du personnel"},
      {value :"Incubation"},
      {value :"International"},
      {value :"Levée de fonds"},
      {value :"Location meublée non professionnel"},
      {value :"Management de transition"},
      {value :"Médiation agréée"},
      {value :"Mise en place des tableaux de bord"},
      {value :"Montage financier immobilier"},
      {value :"Notariat"},
      {value :"Optimisation de la trésorerie"},
      {value :"Optimisation des fonctions supports"},
      {value :"Optimisation fiscale"},
      {value :"Pilotage de trésorerie"},
      {value :"Planification d'entreprise"},
      {value :"Prestation globale d'accompagnement"},
      {value :"Réalisation d'un business plan"},
      {value :"Reporting et consolidation"},
      {value :"Restructuration"},
      {value :"RSE"},
      {value :"Secrétariat juridique"},
      {value :"Service ressources humaines"},
      {value :"Sous-traitance administrative"},
      {value :"Soutien à la création d'entreprise"},
      {value :"Succession"},
      {value :"Succession d'entreprise"},
      {value :"Tenue et révision comptable"},
      {value :"Transmission de données en ligne"},
      {value :"Transmission d'entreprise"},

    ],
    "secteur_dactivite":[
      {value :"Agence d'intérim"},
      {value :"Agriculture - Viticulture"},
      {value :"Agroalimentaire"},
      {value :"Architecture"},
      {value :"Architecture intérieure"},
      {value :"Artisanat - Métiers d'art"},
      {value :"Artiste"},
      {value :"Associations"},
      {value :"Assurance"},
      {value :"Audiovisuel"},
      {value :"Audit"},
      {value :"Auto-entrepreneur"},
      {value :"Automobile"},
      {value :"Banque"},
      {value :"Bâtiment"},
      {value :"Biologie - Chimie"},
      {value :"Capital investissement"},
      {value :"Casinos"},
      {value :"Comités d'entreprises"},
      {value :"Commerce"},
      {value :"Commissaire Priseur"},
      {value :"Communication - Information"},
      {value :"Constitution de PME"},
      {value :"Coopérative"},
      {value :"Culture"},
      {value :"Déclaration de location"},
      {value :"Défense"},
      {value :"Droit"},
      {value :"E-commerce"},
      {value :"Edition"},
      {value :"Electronique"},
      {value :"Energies renouvelables"},
      {value :"Enseignement"},
      {value :"Stockage"},
      {value :"Environnement"},
      {value :"ESS"},
      {value :"ETI"},
      {value :"Evénementiel"},
      {value :"Finance"},
      {value :"Fondations"},
      {value :"Formation"},
      {value :"Franchisé"},
      {value :"Freelance"},
      {value :"Gestion"},
      {value :"Grande distribution"},
      {value :"Héritages"},
      {value :"Hôtellerie"},
      {value :"Humanitaire"},
      {value :"Immobilier"},
      {value :"Impôts"},
      {value :"Imprimerie"},
      {value :"Industrie"},
      {value :"Informatique"},
      {value :"LMNP"},
      {value :"Maintenance"},
      {value :"Mécanique"},
      {value :"Médical"},
      {value :"Médico-social"},
      {value :"Mytiliculture"},
      {value :"Nettoyage"},
      {value :"Notaire"},
      {value :"Nouvelles technologies"},
      {value :"Numérique"},
      {value :"Opticien"},
      {value :"Paramédical"},
      {value :"Particuliers"},
      {value :"Pharmacie"},
      {value :"PME"},
      {value :"Pôles internationaux"},
      {value :"Profession libérale"},
      {value :"Publicité"},
      {value :"Ressources Humaines"},
      {value :"Restauration"},
      {value :"Santé"},
      {value :"SCI"},
      {value :"Secours"},
      {value :"Secrétariat"},
      {value :"Secteur public"},
      {value :"Sécurité"},
      {value :"Services à la personne"},
      {value :"Société de capitaux"},
      {value :"Société de recouvrement"},
      {value :"Société privée"},
      {value :"Sociétés civiles"},
      {value :"Soins du corps"},
      {value :"Spectacle"},
      {value :"Sport"},
      {value :"Startup"},
      {value :"Tabac - Presse"},
      {value :"TMP"},
      {value :"Tourisme"},
      {value :"Transport maritime"},
      {value :"Transport-logistique"},
      {value :"Travailleurs indépendants"},
      {value :"Travaux publics"},
      {value :"VTC"},

  ],
    "langues":[ 
      {value :"Français"},
      {value :"Anglais"},
      {value :"Allemand"},
      {value :"Arabe"},
      {value :"Italien"},
      {value :"Chinois"},
      {value :"Hébreu"},
      {value :"Espagnol"},
      {value :"Portugais"},
      {value :"Russe"},
    ],
    "accessibilites":[
      {value :"Accès personne handicapée"},
      {value :"Ascenseur"},
      {value :"Parking"},
    ],
  });

  
  const handleChange = (event) => {    

    const {
      target: { value ,name},
    } = event;
    /* console.log(event.target)
    console.log(typeof value === 'string' ? value.split(',') : value) */
    setSelectedFilterData({...selectedFilterData,
      name:typeof value === 'string' ? value.split(',') : value,
    })
    
  };
  useEffect(() => {    
    
    setBreadcrumbsData({
      "breadCrumbs":[
        {"pagename":"Experts-comptables","path":"/experts-comptables"}
      ],
      "main":{"pagename":postalCode,"path":"/"}
    })
    // Fetch items from another resources.
    const endOffset = itemOffset + itemPerPage;
    console.log("data",data)
    // setCurrentItems(data.slice(itemOffset, endOffset));
    setCurrentItems(data);
    // setPageCount(Math.ceil(data.length / itemPerPage));
    setPageCount(Math.ceil(paginateData.total_results / itemPerPage))

  }, [postalCode, itemOffset, data,paginateData]);
  // Use Effect
  useEffect(() => {
    let URL =process.env.REACT_APP_API_URL + "api/admin/search-directory-accountant";
    let data = { search: postalCode,size:paginateData.size,currentPage:paginateData.current };

    let config = {
      method: "post",
      url: URL,
      data: data,
      headers: {
        // 'Authorization': `Bearer ${process.env.REACT_APP_ELK_SEARCH_API_KEY}`,
        // 'Accept': '*/*', 
        // "Cache-Control":"no-cache",
        'Content-Type': 'application/json',
      },
    };

    axios
      .request(config)
      .then((response) => {
        setLoadingFullpage(false);
        if(response.data && response.data.data && response.data.data.length>0){
          let jsonData = [];
          response.data.data.map((source_val,key)=>{
            let val = source_val._source;
            jsonData.push({
              "type": "Feature",
              "geometry": {
                "type": "Point",
                "coordinates": [parseFloat(val.longitude),parseFloat(val.latitude)]
              }
            })
          })
          // console.log("jsonData",jsonData);
          setGeoJson({
            "type": "FeatureCollection",
            "features": jsonData
          });
          setLatlng({
            "latitude":response.data.data[0]._source.latitude,
            "longitude":response.data.data[0]._source.longitude,
          })
        }
        setData(response.data.data);
        setPaginateData(response.data.paginateResult);

      })
      .catch((error) => {
        console.log(error);
      });

  }, [postalCode]);
// ======================================getSearchResult
const getSearchResult=(currentPage)=>{
  setLoadingFullpage(true);
    let URL =process.env.REACT_APP_API_URL + "api/admin/search-directory-accountant";
    let data = { search: postalCode,size:paginateData.size,currentPage:currentPage };

    let config = {
      method: "post",
      url: URL,
      data: data,
      headers: { 
        // 'Authorization': `Bearer ${process.env.REACT_APP_ELK_SEARCH_API_KEY}`,
        // 'Accept': '*/*', 
        // "Cache-Control":"no-cache",
        'Content-Type': 'application/json', 
      },
    };

    axios
    .request(config)
    .then((response) => {
      setLoadingFullpage(false);
      console.log(response.data.data);
      if(response.data && response.data.data && response.data.data.length>0){
        let jsonData = [];
        response.data.data.map((source_val,key)=>{
          let val = source_val._source;
          jsonData.push({
            "type": "Feature",
            "geometry": {
              "type": "Point",
              "coordinates": [parseFloat(val.longitude),parseFloat(val.latitude)]
            }
          })
        })
        // console.log("jsonData",jsonData);
        setGeoJson({
          "type": "FeatureCollection",
          "features": jsonData
        });
        setLatlng({
          "latitude":response.data.data[0]._source.latitude,
          "longitude":response.data.data[0]._source.longitude,
        })
      }
      setData(response.data.data);
      setPaginateData(response.data.paginateResult);
    })
    .catch((error) => {
      setLoadingFullpage(false);
      console.log(error);
    });
}

  // ====================================
  const [deCriteres, setDeCriteres] = useState(true);
  const onClick = () => {
    setDeCriteres(!deCriteres);
  };
  // ================================================
  /* =====================|Get Default Values|================== */
  const searchRef = useRef(null);
  const [search, setSearch] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  // Search Input data
  function getInputSearchData(input) {
    setSearchKey(input);
    if (input) {
      /* let data = JSON.stringify({
        "query": input,
        "group": {
          "field": "postalcode"
        }
      }); */
      // let URL = 'https://enterprise-search.kifwat.net/api/as/v1/engines/directory-accountant/search';  
      let URL = process.env.REACT_APP_API_URL + "api/admin/search-directory-accountant";
      let data = { search: input ,size:10,currentPage:1};
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: URL,
        headers: {
          // 'Authorization': `Bearer ${process.env.REACT_APP_ELK_SEARCH_API_KEY}`,
          // 'Accept': '*/*', 
          // "Cache-Control":"no-cache",
          'Content-Type': 'application/json',
        },
        data: data
      };
      axios.request(config)
        .then((response) => {
          if ((response.data.data).length > 0) {
            let resultList = response.data.data.filter((val, i) => val._source.addresslocality && val._source.postalcode)
            setSearch(resultList);
            setCheckList(true);
          } else {
            setSearch(response.data.data);
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error(error);
          setCheckList(false);
        });

      
    } else {
      setCheckList(false);
    }
  }
  // ========================================
  const [checkList, setCheckList] = useState(false);
  const [singleList, setSingleList] = useState(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [search, checkList]);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setCheckList(false);
    }
  };
  const handleOpenMessage = (event,list) => {
    // console.log("handleOpenMessage")
    setIsOpen(true)
    setSingleList(list)
  };

  // ======================|pagination|=========================
  function Items({ currentItems }) {
    // console.log("currentItems", currentItems)
    return (
      <div className="items mb-2">
        {currentItems &&
          currentItems.map((listdata) => {
            let list = listdata._source
            let cleanedStr = "";
            let array = [];
            let url = no_image;
            if (list.logo && list.logo) {
              cleanedStr = list.logo.replace(/^\[('|")?|('|")?\]$/g, "");
              array = cleanedStr.split(",");
              url = array[0].trim();
            }

            return (
              <div className="btnShadow lg:max-w-[723px] w-full h-auto py-8 px-4 bg-white rounded-[21px] mt-8">
                <Link
                  to={"/fiche-experts-comptables/" + decodedStringValue(list.slug) +"/"+ list.id}
                  className="flex sm:flex-row flex-col items-center gap-4"
                >
                  <img src={url} alt={url} style={{"width":"200px"}} />
                  <div className="flex items-start sm:flex-row flex-col justify-between w-full">
                    <div>
                      <h4 className="font-bold text-lg 3xl:text-xl text-primary">
                        {decodedStringValue(list.name)}
                      </h4>
                      <h6 className="text-base font-semibold text-primary">
                        Cabinet comptable
                      </h6>
                      <p className="text-base font-light text-primary">
                        Expert-comptable -  Commissaire aux comptes
                      </p>
                    </div>

                    <div className="flex whitespace-nowrap items-center gap-1 mt-4 sm:my-0">
                      <FiMapPin />
                      <p>
                        {decodedStringValue(list.addresslocality)} {list.postalcode}
                      </p>
                    </div>
                  </div>
                </Link>
                <hr className="my-6 border-black-light opacity-50" />
                <div>
                  <div className="flex gap-2 mt-4">
                    <img src={Compétences} alt="" />
                    <p className="text-base font-normal text-primary">
                      Compétences
                    </p>
                  </div>
                  <div className="mt-4 flex gap-2 items-center flex-wrap  ">
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                      Gestion de la paie{" "}
                    </div>
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                      Commissaire à la fusion{" "}
                    </div>
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                      Commissaire à la fusion{" "}
                    </div>
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                      + 5
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <div className="flex gap-2 mt-4">
                    <img src={Compétences} alt="" />
                    <p className="text-base font-normal text-primary">
                      Secteurs
                    </p>
                  </div>
                  <div className="mt-4 flex gap-2 items-center flex-wrap">
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                      TPE -PME -ETI{" "}
                    </div>
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                      Profession libérale{" "}
                    </div>
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                      Commerce
                    </div>
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                      E-Commerce
                    </div>
                    <div
                      className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                      style={{ background: "rgba(222, 227, 226, 0.5)" }}
                    >
                      + 3
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center mt-8 gap-1 sm:gap-4">
                  <button className="w-full max-w-[252px] h-full min-h-[30px] sm:min-h-[50px] flex items-center justify-center gap-1 sm:gap-3 bg-primary text-white  text-xs sm:text-base font-semibold rounded-md transition-all duration-300 hover:bg-secondary" onClick={(e)=>handleOpenMessage(e,list)} >
                    <img
                      src={messages}
                      alt="messages"
                      className="w-4 h-4 sm:w-auto sm:h-auto"
                    />
                    Message
                  </button>
                  <button className="w-full max-w-[252px] h-full  min-h-[30px] sm:min-h-[50px] flex items-center justify-center gap-1 sm:gap-3 bg-primary text-white text-xs sm:text-base font-semibold rounded-md transition-all duration-300 hover:bg-secondary">
                    <img
                      src={tel}
                      alt="messages"
                      className="w-4 h-4 sm:w-auto sm:h-auto rotate_telephone"
                    />
                    Contacter
                  </button>
                  {/* <button className="w-full max-w-[252px] h-full  min-h-[30px] sm:min-h-[50px] flex items-center justify-center gap-1 sm:gap-3 bg-primary text-white text-xs sm:text-base font-semibold rounded-md transition-all duration-300 hover:bg-secondary">
                    <img
                      src={akarIconsCalendar}
                      alt="akarIconsCalendar"
                      className="w-4 h-4 sm:w-auto sm:h-auto"
                    />
                    Prendre RDV
                  </button> */}
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    // console.log("event",event)
    const newOffset = (event.selected * 4) % data.length;
    // console.log("newOffset",newOffset)
    setItemOffset(newOffset);
    setPaginateData(paginateData => ({ ...paginateData, ["current"]: event.selected+1 }));
    getSearchResult(event.selected+1 );
    /* setPaginateData({
      "current": event.selected+1,
      "total_pages": 0,
      "total_results": 0,
      "size": 10
    }) */
  };
  return (
    <>
      <MessageProFormComp isOpen={isOpen} setSuccessfull={setSuccessfull} setIsOpen={setIsOpen} singleList={singleList} type={"accountant"} />
      {loadingFullpage?
      <PageLoaderComp />
      :""}
      {successfull?
        <ToastMessageComp  message={alert.message} type={alert.type} />
        :""}
      <div className="max-w-[1277px] m-auto my-8 md:mt-5 flex justify-center items-center flex-col gap-4">
        <div className="flex items-center justify-start sm:gap-4 w-full m-auto">
          <Breadcrumb page="Experts-comptables" breadcrumbsData={breadcrumbsData} />
        </div>
        <div
          style={{
            position: "relative",
          }}
        >
          <form
            id="searchForm"
            className="bg-white border-0 max-w-[727px] sm:mx-auto my-6 px-5 py-3 sm:py-6 input-box-shadow rounded flex justify-between w-full"
            style={{ boxShadow: "0px 2px 12px rgba(83, 83, 83, 0.25)",zIndex:"9" }}
          >
            <input
              type="text"
              onInput={(e) => getInputSearchData(e.target.value)}
              defaultValue={postalCode}
              className=" w-full outline-none text-[#797979] text-base font-light"
              placeholder="Nom de la ville ou du département"
            />
            <button>
              <img src={searchIcon} alt="searchIcon" />
            </button>
          </form>
          <div
            style={{
              display: checkList ? "block" : "none",
              left: "0",
              right: "0",
              top: "80px",
              zIndex:"9"
              
            }}
            className="searchListBox max-w-[727px] sm:mx-auto"
            ref={searchRef}
          >
            <ul>
              {search.length === 0 ? (
                <div
                  className="containertype"
                  style={{ textAlign: "left", paddingTop: "9px 19px" }}
                >
                  <span>No search result found!</span>
                 {/*  <div className="dottype"></div>
                  <div className="dottype"></div>
                  <div className="dottype"></div> */}
                </div>
              ) : (
                ""
              )}
              {search.map((searchList) => {
                let list = searchList._source;
                return (
                  <li>
                    <Link
                      className="ctmref"
                      to={"/fiche-experts-comptables/" + decodedStringValue(list.slug) +"/"+ list.id}
                      
                    >
                      {list.name}  ({list.addresslocality}, {list.postalcode})
                    </Link>
                  </li>
                );
              })}
              <li className={search.length>0?"":"hidden"} style={{"fontWeight":"700"}}>
                <Link
                  onClick={(e)=>{
                    setCheckList(false)
                    /* setPaginateData({
                      "current": 1,
                      "total_pages": 0,
                      "total_results": 0,
                      "size": 100
                    }) */
                  }}
                  className="ctmref"
                  // to={`/directory_search_result/accountant/${searchKey}`}
                  to={`/annuaire-des-experts-comptable-selection/accountants/${searchKey}`}
                >
                  {"Voir tous les résultats de la recherche"}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="max-w-[900px] pb-6 m-auto">
          <div className="flex items-center justify-center w-full gap-2">
            <div className="h-[0.5px] bg-[#DEE3E2] block w-full"> </div>
            <p
              className=" font-medium text-lg text-primary min-w-[120px] m-auto text-center cursor-pointer"
              onClick={onClick}
            >
              {" "}
              + de critères
            </p>
            <div className="h-[0.5px] bg-[#DEE3E2] block w-full"> </div>
          </div>

          {deCriteres ? (
            <div className="flex sm:items-center flex-wrap sm:justify-center gap-2 sm:gap-6 mt-5 transition-all duration-300">
              {/* <button className="text-lg font-light text-primary py-2 px-2 sm:px-5 border rounded-md border-primary hover:bg-secondary hover:border-secondary hover:text-white">
                  Compétences
              </button> */}

              <FormControl sx={{ m: 1 ,width:"150px"}}>
                <InputLabel id="demo-multiple-checkbox-label">Compétences</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedFilterData.competences}
                  name="competences"
                  onChange={handleChange}
                  input={<OutlinedInput label="Compétences" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {filterData.competences.map((name,index) => (
                    <MenuItem style={{"padding":"2px"}}  key={name.value} value={name.value}>
                      <Checkbox checked={selectedFilterData.competences.indexOf(name.value) > -1} style={{"padding":"3px"}} /> 
                      <ListItemText primary={name.value}  style={{"fontSize":"12px"}} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1 ,width:"200px" }}>
                <InputLabel id="demo-multiple-checkbox-label">Secteur d’activité</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedFilterData.secteur_dactivite}
                  name="secteur_dactivite"
                  onChange={handleChange}
                  input={<OutlinedInput label="Secteur d’activité" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {filterData.secteur_dactivite.map((name,index) => (
                    <MenuItem key={name.value} value={name.value}>
                      <Checkbox  checked={selectedFilterData.secteur_dactivite.indexOf(name.value) > -1} /> 
                      <ListItemText primary={name.value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1  ,width:"150px"}}>
                <InputLabel id="demo-multiple-checkbox-label">Langues</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  name="langues"
                  value={selectedFilterData.langues}
                  onChange={handleChange}
                  input={<OutlinedInput label="Langues" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {filterData.langues.map((name) => (
                    <MenuItem key={name.value} value={name.value}>
                      <Checkbox checked={selectedFilterData.langues.indexOf(name.value) > -1}  />
                      <ListItemText primary={name.value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1  ,width:"150px"}}>
                <InputLabel id="demo-multiple-checkbox-label">Accessibilités</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedFilterData.accessibilites}
                  name="accessibilites"
                  onChange={handleChange}
                  input={<OutlinedInput label="Accessibilités" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {filterData.accessibilites.map((name) => (
                    <MenuItem key={name.value} value={name.value}>
                      <Checkbox  checked={selectedFilterData.accessibilites.indexOf(name.value) > -1} />
                      <ListItemText primary={name.value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <button className="text-lg font-light text-primary py-2 px-2 sm:px-5 border rounded-md border-primary  hover:bg-secondary hover:border-secondary hover:text-white">
                Secteur d’activité
              </button> */}
              {/* <button className="text-lg font-light text-primary py-2 px-2 sm:px-5 border rounded-md border-primary  hover:bg-secondary hover:border-secondary hover:text-white">
                Langues
              </button> */}
              {/* <button className="text-lg font-light text-primary py-2 px-2 sm:px-5 border rounded-md border-primary  hover:bg-secondary hover:border-secondary hover:text-white">
                  Accessibilités
              </button> */}
            </div>
          ) : null}
        </div>
      </div>
      <div className="w-full bg-mainBg ">
        <div className="grid grid-cols-12 ">
          <div className="col-span-12 lg:col-span-6 3xl:col-span-6 px-4 py-10  h-screen  overflow-auto">
            <h5 className="text-lg font-normal text-primary">
              <span className="font-semibold">({paginateData.total_results})</span> Résultat
              pour Experts-comptables{" "}
              <span className="font-semibold">{postalCode}</span>
            </h5>
            {/* ============================================== */}
            {/* ============================================== */}
            {/* {data.map((list) => {
              const cleanedStr = list.logo.replace(/^\[('|")?|('|")?\]$/g, "");
              const array = cleanedStr.split(",");
              const url = array[0].trim();
              return (
                <div className="btnShadow lg:max-w-[723px] w-full h-auto py-8 px-4 bg-white rounded-[21px] mt-8">
                  <Link
                    to={"/fiche-experts-comptables/" + list.id}
                    className="flex sm:flex-row flex-col items-center gap-4"
                  >
                    <img src={url} alt={url} />
                    <div className="flex items-start sm:flex-row flex-col justify-between w-full">
                      <div>
                        <h4 className="font-bold text-lg 3xl:text-xl text-primary">
                          {list.name}
                        </h4>
                        <h6 className="text-base font-semibold text-primary">
                          Cabinet comptable
                        </h6>
                        <p className="text-base font-light text-primary">
                          Expert-comptable - Commissaire aux comptes
                        </p>
                      </div>

                      <div className="flex whitespace-nowrap items-center gap-1 mt-4 sm:my-0">
                        <FiMapPin />
                        <p>
                          {list.addressLocality} {list.postalCode}
                        </p>
                      </div>
                    </div>
                  </Link>
                  <hr className="my-6 border-black-light opacity-50" />

                  <div>
                    <div className="flex gap-2 mt-4">
                      <img src={Compétences} alt="" />
                      <p className="text-base font-normal text-primary">
                        Compétences
                      </p>
                    </div>
                    <div className="mt-4 flex gap-2 items-center flex-wrap  ">
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        Gestion de la paie{" "}
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        Commissaire à la fusion{" "}
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        Commissaire à la fusion{" "}
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        + 5
                      </div>
                    </div>
                  </div>

                  <div className="mt-8">
                    <div className="flex gap-2 mt-4">
                      <img src={Compétences} alt="" />
                      <p className="text-base font-normal text-primary">
                        Secteurs
                      </p>
                    </div>
                    <div className="mt-4 flex gap-2 items-center flex-wrap">
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        TPE -PME -ETI{" "}
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        Profession libérale{" "}
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        Commerce
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        E-Commerce
                      </div>
                      <div
                        className="rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit"
                        style={{ background: "rgba(222, 227, 226, 0.5)" }}
                      >
                        + 3
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between items-center mt-8 gap-1 sm:gap-4">
                    <button className="w-full max-w-[252px] h-full min-h-[30px] sm:min-h-[50px] flex items-center justify-center gap-1 sm:gap-3 bg-primary text-white  text-xs sm:text-base font-semibold rounded-md transition-all duration-300 hover:bg-secondary">
                      <img
                        src={messages}
                        alt="messages"
                        className="w-4 h-4 sm:w-auto sm:h-auto"
                      />
                      Message
                    </button>
                    <button className="w-full max-w-[252px] h-full  min-h-[30px] sm:min-h-[50px] flex items-center justify-center gap-1 sm:gap-3 bg-primary text-white text-xs sm:text-base font-semibold rounded-md transition-all duration-300 hover:bg-secondary">
                      <img
                        src={tel}
                        alt="messages"
                        className="w-4 h-4 sm:w-auto sm:h-auto rotate_telephone"
                      />
                      Contacter
                    </button>
                    <button className="w-full max-w-[252px] h-full  min-h-[30px] sm:min-h-[50px] flex items-center justify-center gap-1 sm:gap-3 bg-primary text-white text-xs sm:text-base font-semibold rounded-md transition-all duration-300 hover:bg-secondary">
                      <img
                        src={akarIconsCalendar}
                        alt="akarIconsCalendar"
                        className="w-4 h-4 sm:w-auto sm:h-auto"
                      />
                      Prendre RDV
                    </button>
                  </div>
                </div>
              );
            })} */}
            {/* ====================================================== */}
            <Items currentItems={currentItems} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: " center",
              }}
            >
              <ReactPaginate
                nextLabel="next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
            {/* <nav
              aria-label="Page navigation example"
              className="mt-8 mx-auto w-full flex items-center justify-between"
            >
              <Link
                to=""
                className="flex items-center justify-center gap-2 text-xs sm:text-base font-medium text-gary bg-white py-2 px-3 rounded-md hover:bg-primary hover:text-white"
                style={{ boxShadow: "(0px 4px 2px rgba(240, 240, 240, 0.25)" }}
              >
                <IoIosArrowBack /> Précédent
              </Link>
              <ul className="inline-flex  space-x-3">
                <li>
                  <Link
                    to=""
                    className="text-base font-medium w-9 h-9 rounded-md flex items-center justify-center bg-primary text-white"
                  >
                    1
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    className="text-base font-medium w-9 h-9 bg-white rounded-md flex items-center justify-center text-primary hover:bg-primary hover:text-white"
                  >
                    2
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    className="text-base font-medium w-9 h-9 bg-white rounded-md flex items-center justify-center text-primary hover:bg-primary hover:text-white"
                  >
                    3
                  </Link>
                </li>
                <li className="hidden sm:block">
                  <Link
                    to=""
                    className="text-base font-medium w-9 h-9 bg-white rounded-md flex items-center justify-center text-primary hover:bg-primary hover:text-white"
                  >
                    4
                  </Link>
                </li>
              </ul>
              <Link
                to=""
                className="flex items-center justify-center gap-2 text-xs sm:text-base font-medium text-gary bg-white py-2 px-3 rounded-md hover:bg-primary hover:text-white"
                style={{ boxShadow: "(0px 4px 2px rgba(240, 240, 240, 0.25)" }}
              >
                Suivant
                <IoIosArrowForward />{" "}
              </Link>
            </nav> */}
          </div>
          <div className="col-span-12 lg:col-span-6 3xl:col-span-6">
            {/* <img src={mapDemo} alt="mapDemo" className='h-screen w-full' /> */}
            <Mapbox geoJson={geoJson} latitude={latlng.latitude} longitude={latlng.longitude} />
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5666151.454104781!2d-3.2305647495523675!3d46.09744499642553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd54a02933785731%3A0x6bfd3f96c747d9f7!2sFrance!5e0!3m2!1sen!2sin!4v1685359635891!5m2!1sen!2sin"
              style={{ width: "100%", height: "100%", minHeight: "785px" }}
              title="france"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
