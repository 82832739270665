/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import axios from "axios";
import SideBar from "./SideBar";
import "./Style.css";
import { Link, Navigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Footer from "../component/Footer";
import SearchHead from "./SearchHead";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../_actions";
import { getMonthsStartingWithCurrent, history } from "../_helpers";
import { alertActions } from "../_actions";
import moment from "moment";
import ScreenWrapper from "./ScreenWrapper";

export default function Dashboard() {
  const [months, setMonths] = useState([]);
  const [dashdata, setDashData] = useState({
    no_advertisement_publish: 0,
    no_pending_announcement: 0,
    no_invoices: 0,
    publish_advertise: [],
    forfaitaires: 0,
    modification: 0,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    const monthsArray = getMonthsStartingWithCurrent();
    setMonths(monthsArray);
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
    getDashboardData();
  }, []);
  const getDashboardData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/Dashboard/${currentUser.data.id}`
      );

      if (response.data.sucess === true) {
        let orderdevis = response.data.data.orderdevis;
        let orderdossiers = response.data.data.orderdossiers;
        let publish_advertise = response.data.data.publish_advertise;
        let userDetailsGet = response.data.data.userDetails;
        setDashData({
          no_advertisement_publish: orderdevis.length,
          no_pending_announcement: orderdossiers.length,
          no_invoices: orderdevis.length,
          publish_advertise: publish_advertise,
          forfaitaires: userDetailsGet ? userDetailsGet.forfaitaires : "",
          modification: userDetailsGet ? userDetailsGet.modification : "",
        });
      }
    } catch (error) {
      // // console.log(error);
    }
  };
  const currentUser = useSelector((state) => state.authentication.user);
  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <ScreenWrapper>
        <div className="xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px]">
          <div className="mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 sm:justify-between">
            <div>
              <h1 className="text-lg sm:text-2xl xl:text-2xl 2xl:text-[25px] font-black text-white sm:mt-0 mt-4">
                Tableau de bord
              </h1>
            </div>
            <div className="w-full sm:w-auto flex justify-end">
              {/* <SearchHead /> */}
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 mt-9 px-4 sm:px-9  gap-2 sm:gap-5">
            <Link
              to="/userdashboard/annonces-publiees"
              className="AnnoncesA px-3 sm:px-5 py-5 flex items-center justify-start gap-4"
            >
              <svg
                width="43"
                height="53"
                viewBox="0 0 43 53"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.4">
                  <path
                    d="M3.15771 9.54053V43.1683"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M40 19.6338V43.1845"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.29834 2.81152H24.6491"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.29834 49.9131H33.8596"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M39.9997 43.1846C40.0165 46.5451 36.9295 49.9133 33.8594 49.9133"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.15802 43.1846C3.15802 46.549 6.22818 49.9133 9.29834 49.9133"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.15802 9.53564C3.15802 6.17614 6.22818 2.88669 9.29834 2.81176"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M39.983 19.6485L24.6489 2.81152"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.6487 12.9053C24.6582 16.2513 27.7313 19.634 30.7891 19.634"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.6489 12.9047V2.81152"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M30.7891 19.6338H39.9995"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.29834 43.1846H18.5088"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.29834 36.4556H24.6491"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.29834 29.7266H18.5088"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
              <div className="flex flex-col justify-center items-start gap-0">
                <p className="text-lg font-normal text-white">
                  Annonces publiées
                </p>
                <h4 className="text-[35px] font-extrabold text-white leading-7">
                  {dashdata.no_advertisement_publish}
                </h4>
              </div>
            </Link>

            <Link
              to="/userdashboard/annonces-en-attente"
              className="AnnoncesB px-3 sm:px-5 py-5 flex items-center justify-start gap-4"
            >
              <svg
                width="49"
                height="57"
                viewBox="0 0 49 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.4">
                  <path
                    d="M3 9.54041V43.1682"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M41.3335 19.6338V43.1845"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.38916 2.81152H25.3614"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.38916 49.9131H34.9447"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M41.3332 43.1846C41.3507 46.5451 38.1388 49.9133 34.9443 49.9133"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.00028 43.1846C3.00028 46.549 6.19472 49.9133 9.38916 49.9133"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.00028 9.53564C3.00028 6.17614 6.19472 2.88669 9.38916 2.81176"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M41.3161 19.6485L25.3613 2.81152"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25.3611 12.9053C25.3709 16.2513 28.5684 19.634 31.75 19.634"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25.3613 12.9047V2.81152"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M31.75 19.6338H41.3333"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.38916 43.1846H18.9725"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.38916 36.4556H25.3614"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.38916 29.7266H18.9725"
                    stroke="white"
                    strokeWidth="5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <ellipse
                  cx="36.7335"
                  cy="44.8408"
                  rx="12.2667"
                  ry="11.5942"
                  fill="#6C3ABE"
                />
                <g opacity="0.5">
                  <path
                    d="M36.7335 54.5024C42.379 54.5024 46.9557 50.1766 46.9557 44.8405C46.9557 39.5045 42.379 35.1787 36.7335 35.1787C31.0879 35.1787 26.5112 39.5045 26.5112 44.8405C26.5112 50.1766 31.0879 54.5024 36.7335 54.5024Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M39.8001 48.7053L37.3324 46.3729C36.949 46.0106 36.7335 45.5192 36.7334 45.0067V39.0435"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>

              <div className="flex flex-col justify-center items-start gap-0">
                <p className="text-lg font-normal text-white">
                  Annonces en attente
                </p>
                <h4 className="text-[35px] font-extrabold text-white leading-7">
                  {dashdata.no_pending_announcement}
                </h4>
              </div>
            </Link>

            <Link
              to="/userdashboard/factures"
              className="AnnoncesC px-3 sm:px-5 py-5 flex items-center justify-start gap-3 sm:gap-4"
            >
              <svg
                width="42"
                height="50"
                viewBox="0 0 42 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.5"
                  d="M11.3077 23.3167H9.69231V26.65H11.3077V23.3167ZM30.6923 26.65H32.3077V23.3167H30.6923V26.65ZM11.3077 31.6667H9.69231V33.3333H11.3077V31.6667ZM30.6923 31.6667V33.3333H32.3077V31.6667H30.6923ZM11.3077 18.3333V16.6667H9.69231V18.3333H11.3077ZM30.6923 18.3333H32.3077V16.6667H30.6923V18.3333ZM40.3846 11.6667H42V10.9767L41.5283 10.4867L40.3846 11.6667ZM30.6923 1.66667L31.836 0.486667L31.3611 0H30.6923V1.66667ZM11.3077 26.65H30.6923V23.3167H11.3077V26.65ZM11.3077 33.3333H30.6923V30H11.3077V33.3333ZM11.3077 20H30.6923V16.6667H11.3077V20ZM37.1538 46.6667H4.84615V50H37.1538V46.6667ZM3.23077 45V5H0V45H3.23077ZM38.7692 11.6667V45H42V11.6667H38.7692ZM4.84615 3.33333H30.6923V0H4.84615V3.33333ZM29.5486 2.84667L39.2409 12.8467L41.5283 10.4867L31.836 0.486667L29.5486 2.84667ZM4.84615 46.6667C4.41773 46.6667 4.00685 46.4911 3.7039 46.1785C3.40096 45.866 3.23077 45.442 3.23077 45H0C0 46.3261 0.510576 47.5979 1.41941 48.5355C2.32824 49.4732 3.56087 50 4.84615 50V46.6667ZM37.1538 50C38.4391 50 39.6718 49.4732 40.5806 48.5355C41.4894 47.5979 42 46.3261 42 45H38.7692C38.7692 45.442 38.599 45.866 38.2961 46.1785C37.9932 46.4911 37.5823 46.6667 37.1538 46.6667V50ZM3.23077 5C3.23077 4.55797 3.40096 4.13405 3.7039 3.82149C4.00685 3.50893 4.41773 3.33333 4.84615 3.33333V0C3.56087 0 2.32824 0.526784 1.41941 1.46447C0.510576 2.40215 0 3.67392 0 5H3.23077ZM9.69231 18.3333V31.6667H12.9231V18.3333H9.69231ZM19.3846 18.3333V31.6667H22.6154V18.3333H19.3846ZM29.0769 18.3333V31.6667H32.3077V18.3333H29.0769ZM9.69231 13.3333H19.3846V10H9.69231V13.3333ZM22.6154 40H32.3077V36.6667H22.6154V40Z"
                  fill="white"
                />
              </svg>

              <div className="flex flex-col justify-center items-start gap-0">
                <p className="text-lg font-normal text-white">Factures</p>
                <h4 className="text-[35px] font-extrabold text-white leading-7">
                  {dashdata.no_invoices}
                </h4>
              </div>
            </Link>
          </div>
          <div className="mt-12 px-9 ">
            <h4 className="text-xl font-semibold text-primary">
              Solde des remises accordées{" "}
            </h4>
            <p className="text-base font-light text-primary">0 €</p>
          </div>

          <div className="mt-12 px-9 ">
            <h4 className="text-xl font-bold text-primary">
              Vos remises accordées
            </h4>
            <h4 className="text-xl font-light text-primary">
              Remises annonces
            </h4>
            <p className="text-base font-light text-primary">
              Type Forfaitaires : {dashdata.forfaitaires}%
            </p>
            <p className="text-base font-light text-primary">
              Type Modification : {dashdata.modification}%
            </p>
          </div>

          <div className="mt-12 px-9 ">
            <h4 className="text-xl font-semibold text-primary">
              Nb d’annonces publiées{" "}
            </h4>
            <p className="text-base font-light text-primary">
              Année {moment().format("YYYY")}
            </p>
          </div>

          <div className="mt-8 px-9">
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ borderTop: "1px solid #DEE3E2" }}>
                  <TableRow>
                    {months.map((month, index) => (
                      <TableCell
                        key={index}
                        className={
                          index <= 6
                            ? "text-base font-semibold text-primary"
                            : "text-base font-light text-black-light"
                        }
                      >
                        {month}
                      </TableCell>
                    ))}
                    {/* <TableCell sx={{ paddingX: 0 }} align='left' className='text-base font-semibold text-primary'>Janvier</TableCell>
                          <TableCell className='text-base font-semibold text-primary'>Février</TableCell>
                          <TableCell className='text-base font-semibold text-primary'>Mars</TableCell>
                          <TableCell className='text-base font-semibold text-primary'>Avril</TableCell>
                          <TableCell className='text-base font-semibold text-primary'>Mai</TableCell>
                          <TableCell className='text-base font-semibold text-primary'>Juin</TableCell>
                          <TableCell className='text-base font-semibold text-primary'>Juillet</TableCell>
                          <TableCell className='text-base font-semibold text-primary'>Août</TableCell>
                          <TableCell className='text-base font-light text-black-light'>Septembre</TableCell>
                          <TableCell className='text-base font-light text-black-light'>Octobre</TableCell>
                          <TableCell className='text-base font-light text-black-light'>Novembre</TableCell>
                          <TableCell className='text-base font-light text-black-light'>Décembre</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      {dashdata.publish_advertise.length > 0
                        ? dashdata.publish_advertise[0].count
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {dashdata.publish_advertise.length > 0
                        ? dashdata.publish_advertise[1].count
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {dashdata.publish_advertise.length > 0
                        ? dashdata.publish_advertise[2].count
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {dashdata.publish_advertise.length > 0
                        ? dashdata.publish_advertise[3].count
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {dashdata.publish_advertise.length > 0
                        ? dashdata.publish_advertise[4].count
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {dashdata.publish_advertise.length > 0
                        ? dashdata.publish_advertise[5].count
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {dashdata.publish_advertise.length > 0
                        ? dashdata.publish_advertise[6].count
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {dashdata.publish_advertise.length > 0
                        ? dashdata.publish_advertise[7].count
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {dashdata.publish_advertise.length > 0
                        ? dashdata.publish_advertise[8].count
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {dashdata.publish_advertise.length > 0
                        ? dashdata.publish_advertise[9].count
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {dashdata.publish_advertise.length > 0
                        ? dashdata.publish_advertise[10].count
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {dashdata.publish_advertise.length > 0
                        ? dashdata.publish_advertise[11].count
                        : 0}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </ScreenWrapper>

      <Footer />
    </>
  );
}
